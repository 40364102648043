<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense style="z-index: 2">
      <v-toolbar-title>Gestión de medios</v-toolbar-title>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :disabled="disabledByRol"
            fab
            bottom
            right
            absolute
            color="secondary"
            @click="showAddMedioDialog"
          >
            <v-icon color="black">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Añadir medio</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container class="py-0 ma-0" fluid ref="firstContainer">
        <v-row class="mt-0" dense>
          <v-col cols="10">
            <v-autocomplete
              v-model="tableFilters"
              return-object
              :items="headers.filter(x => x.text !== 'Acciones')"
              :items-text="(h) => {return h.text}"
              item-title="text"
              label="Mostrar campos"
              multiple
              clearable
              dense
              outlined
            >
              <template #selection="{ item, index }">
                <v-chip v-if="index < maxVisible" :key="item.value" small>
                  {{ item.text }}
                </v-chip>
                <span v-else-if="index===maxVisible" :key="index" small>
                  {{ computedHeaders.length - maxVisible - 1 }} más
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              color="teal"
              @click="resetFilters()"
            >
              <v-icon>mdi-cached</v-icon>
            </v-btn>
            <v-btn
              icon
              color="teal"
              @click="mostrarFilAv = !mostrarFilAv"
            >
              <v-icon v-if="!mostrarFilAv">mdi-filter-menu</v-icon>
              <v-icon v-else>mdi-filter-minus-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              color="teal"
              @click="downloadExcelMedios()"
            >
              <v-icon>mdi-file-excel-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              @click="exportarMediosPDF()"
            >
              <v-icon>mdi-file-pdf-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense class="mt-0">
          <v-col cols="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda general"
              clearable
              class="no-spacing"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field class="no-spacing" label="Medio" v-model="medioSelected" />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              class="no-spacing"
              return-object
              v-model="tipoSelected"
              :items="tiposItems()"
              :item-text="(tipo) => {return tipo.TIPO}"
              item-title="TIPO"
              item-value="ID_MEDIO_TIPO"
              label="Tipo"
              clearable
            />
          </v-col>
          <v-col cols="1">
            <v-autocomplete
              class="no-spacing"
              return-object
              v-model="categoriaSelected"
              :items="categoriaItems()"
              :item-text="(categoria) => {return categoria.CATEGORIA}"
              item-title="CATEGORIA"
              item-value="ID_MEDIO_CATEGORIA"
              label="Categoría"
              clearable
            />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Nombre" v-model="nombreSelected" />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Fecha ult. posicion" v-model="fechaUltPosicionSelected" hint="DD/MM/YYYY" />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              label="Organismo"
              v-model="organismoSelected"
              :items="organismos"
              :item-text="(organismo) => {return organismo.ORGANISMO}"
              item-title="ORGANISMO"
              item-value="ID_ORGANISMO"
              return-object
              clearable
              class="no-spacing"
            />
          </v-col>
        </v-row>
        <v-row dense class="mt-0" v-show="mostrarFilAv">
          <v-col cols="3">
            <v-autocomplete
              v-model="baseSelected"
              class="no-spacing"
              return-object
              :items="baseItems()"
              :item-text="(base) => {return base.NOMBRE}"
              item-title="NOMBRE"
              item-value="ID_BASE"
              label="Base"
              clearable
            />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Tel. interno" v-model="tlfInternoSelected" clearable />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Tel. externo" v-model="tlfExternoSelected" clearable />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Hora entrada" hint="HH:mm" v-model="hEntradaSelected" v-mask="'##:##'" clearable />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Hora salida" hint="HH:mm" v-model="hSalidaSelected" v-mask="'##:##'" clearable />
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid ref="secondContainer">
        <v-row>
          <v-data-table
            class="table"
            :headers="computedHeaders"
            :items="mediosFilter"
            :search="search"
            fixed-header
            disable-pagination
            hide-default-footer
            :style="{ height: `calc(${availableSpace}vh)` }"
          >
            <!-- :items-per-page="30" -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <template #[`item.TELEFONO_INTERNO`]="{ item }">
              <span>{{ item.TELEFONO_INTERNO }}</span>
            </template>

            <template #[`item.TELEFONO_EXTERNO`]="{ item }">
              <a :href="'tel:+34' + item.TELEFONO_EXTERNO">{{
                item.TELEFONO_EXTERNO
              }}</a>
            </template>

            <template #[`item.ULT_POSICION`]="{ item }">
              <span>{{ item.ULT_POSICION ? item.ULT_POSICION.FECHA : "" | formatDate }}</span>
            </template>

            <template #[`item.ACCIONES`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabledByRol"
                    icon
                    @click="showEditMedioDialog(item)"
                  >
                    <v-icon color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabledByRol"
                    icon
                    @click="acceptDeleteMedio(item)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </v-card-text>

    <vx-dialog-add-edit-medio
      :show="showDialog"
      :isEdit="isEdit"
      :editedMedio="editMedio"
      @cancelar="hideAddMedio"
      @addMedio="acceptAddMedio"
      @editMedio="acceptEditMedio"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DialogAddEditMedio from './DialogAddEditMedio'

import constants from '../../helpers/constants'
import * as ExcelHelper from '../../helpers/ExcelHelper'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  components: {
    'vx-dialog-add-edit-medio': DialogAddEditMedio
  },

  data: () => ({
    headers: [
      { text: 'Medio', value: 'MEDIO', align: 'left', sortable: true, initialShow: true },
      { text: 'Tipo', value: 'TIPO', align: 'left', sortable: true, initialShow: true },
      { text: 'Categoría', value: 'CATEGORIA', align: 'left', sortable: true, initialShow: true },
      { text: 'Nombre', value: 'NOMBRE', align: 'left', sortable: true, initialShow: true },
      { text: 'Tel. interno', value: 'TELEFONO_INTERNO', align: 'left', sortable: false, initialShow: true },
      { text: 'Tel. externo', value: 'TELEFONO_EXTERNO', align: 'left', sortable: false, initialShow: true },
      // { text: 'Zona', value: 'ZONA', align: 'left', sortable: true },
      { text: 'Guardias', value: 'GUARDIAS', align: 'left', sortable: true, initialShow: true },
      {
        text: 'Fecha ult. posicion',
        value: 'ULT_POSICION',
        align: 'left',
        sort: (a, b) => {
          if (!a || !a.FECHA) return -1
          if (!b || !b.FECHA) return 1
          return new Date(a.FECHA) - new Date(b.FECHA)
        },
        initialShow: true
      },
      { text: 'Hora entrada', value: 'HORA_ENTRADA', align: 'left', sortable: true, initialShow: true },
      { text: 'Hora salida', value: 'HORA_SALIDA', align: 'left', sortable: true, initialShow: true },
      { text: 'Base', value: 'BASE', align: 'left', sortable: true, initialShow: true },
      { text: 'Organismo', value: 'ORGANISMO', align: 'left', sortable: true, initialShow: true },
      { text: 'Acciones', value: 'ACCIONES', align: 'center', width: '10%', sortable: false, initialShow: true }
    ],

    showDialog: false,
    mostrarFilAv: false,

    search: '',
    medioSelected: '',
    tipoSelected: '',
    categoriaSelected: '',
    nombreSelected: '',
    fechaUltPosicionSelected: '',
    activoSelected: true,
    organismoSelected: '',
    tlfInternoSelected: '',
    tlfExternoSelected: '',
    hEntradaSelected: '',
    hSalidaSelected: '',
    baseSelected: '',
    mediosFilter: [],

    editMedio: {},
    isEdit: false,
    availableSpace: 0,

    tableFilters: [],
    maxVisible: 12

  }),

  computed: {
    ...mapGetters('medio', [
      'medios', 'tiposMedio', 'categoriasMedio', 'bases'
    ]),

    organismos () {
      return [...this.$store.getters['organismo/organismos']].sort((a, b) => constants.collator.compare(a.ORGANISMO, b.ORGANISMO))
    },

    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    },

    computedHeaders () {
      return this.headers.filter(x => (this.tableFilters.length > 0 && x.text === 'Acciones') || this.tableFilters.includes(x))
    }
  },

  watch: {
    medios () {
      this.filterMedios()
    },
    medioSelected () {
      this.filterMedios()
    },
    tipoSelected () {
      this.filterMedios()
    },
    categoriaSelected () {
      this.filterMedios()
    },
    nombreSelected () {
      this.filterMedios()
    },
    fechaUltPosicionSelected () {
      this.filterMedios()
    },
    activoSelected () {
      this.filterMedios()
    },
    tlfInternoSelected () {
      this.filterMedios()
    },
    tlfExternoSelected () {
      this.filterMedios()
    },
    hEntradaSelected () {
      this.filterMedios()
    },
    hSalidaSelected () {
      this.filterMedios()
    },
    baseSelected () {
      this.filterMedios()
    },
    organismoSelected () {
      this.filterMedios()
    }
  },

  methods: {
    ...mapActions('medio', ['addMedio', 'updateMedio', 'deleteMedio']),

    showAddMedioDialog () {
      this.editMedio = null
      this.showDialog = true
      this.isEdit = false
    },

    showEditMedioDialog (item) {
      this.editMedio = Object.assign({}, item)
      this.showDialog = true
      this.isEdit = true
    },

    acceptDeleteMedio (medio) {
      let msg = 'Eliminando el medio: ' + medio.MEDIO
      this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
        if (result) {
          this.$store.dispatch('medio/deleteMedio', medio)
        }
      })
    },

    acceptAddMedio (medio) {
      this.addMedio(medio)
      this.hideAddMedio()
    },

    acceptEditMedio (medio) {
      this.updateMedio(medio)
      this.hideAddMedio()
    },

    hideAddMedio () {
      this.showDialog = false
      this.editMedio = null
    },

    exportarMediosPDF () {
      // Exporta medios terrestres y fechas a un PDF
      let bodyPdf = []
      bodyPdf.push(['Medio', 'Tipo', 'Fecha'])

      let mediosFechaOk = this.medios.filter((x) =>
        this.$date.parseDate(x.FECHA).isValid()
      )
      let mediosFechaMal = this.medios.filter(
        (x) => !this.$date.parseDate(x.FECHA).isValid()
      )

      let mediosOrdenados = mediosFechaOk.sort((a, b) => {
        let aOrden = this.$date.parseDate(a.FECHA)
        let bOrden = this.$date.parseDate(b.FECHA)

        return bOrden > aOrden ? 1 : -1
      })

      for (let i = 0; i < mediosFechaMal.length; i++) {
        mediosOrdenados.push(mediosFechaMal[i])
      }

      for (let i = 0; i < mediosOrdenados.length; i++) {
        let medio = mediosOrdenados[i]

        let datosMedio = []
        datosMedio.push({ text: medio.MEDIO, bold: true })
        datosMedio.push(medio.TIPO)
        datosMedio.push(this.$date.formatDate(medio.FECHA, 'DD/MM/YYYY HH:mm'))
        bodyPdf.push(datosMedio)
      }

      let docDefinition = {
        content: [],
        defaultStyle: {
          fontSize: 9
        }
      }

      docDefinition.content.push(constants.headerPDF)

      docDefinition.content.push(
        {
          text: [
            { text: 'Fecha/hora informe: ', bold: true },
            this.$date.currentDate()
          ],
          margin: [0, 0, 0, 15]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            headerRows: 1,
            body: bodyPdf
          },
          margin: [0, 15, 0, 20]
        }
      )

      pdfMake
        .createPdf(docDefinition)
        .download(
          'Medios_Fecha_' + this.$date.now().format('YYYY_MM_DD_hh_mm')
        )
    },
    async filterMedios () {
      let mediosFilter = []
      if (this.medios) {
        mediosFilter = [...this.medios]

        if (this.medioSelected !== null && this.medioSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.MEDIO.includes(this.medioSelected))
        }

        if (this.tipoSelected !== null && this.tipoSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.TIPO.includes(this.tipoSelected.TIPO))
        }

        if (this.categoriaSelected !== null && this.categoriaSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.CATEGORIA.includes(this.categoriaSelected.CATEGORIA))
        }

        if (this.nombreSelected !== null && this.nombreSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.NOMBRE && x.NOMBRE.toLowerCase().includes(this.nombreSelected.toLowerCase()))
        }

        if (this.fechaUltPosicionSelected !== null && this.fechaUltPosicionSelected !== '') {
          mediosFilter = mediosFilter.filter(x => {
            // Verificar si la fecha en ULT_POSICION es válida
            if (x.ULT_POSICION && x.ULT_POSICION.FECHA) {
              return this.$date.formatDate(x.ULT_POSICION.FECHA, 'DD/MM/YYYY HH:mm').includes(this.fechaUltPosicionSelected)
            } else {
              return false
            }
          })
        }

        if (this.baseSelected !== null && this.baseSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.BASE === this.baseSelected.NOMBRE)
        }

        if (this.tlfInternoSelected != null && this.tlfInternoSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.TELEFONO_INTERNO !== null && x.TELEFONO_INTERNO.includes(this.tlfInternoSelected))
        }

        if (this.tlfExternoSelected != null && this.tlfExternoSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.TELEFONO_EXTERNO !== null && x.TELEFONO_EXTERNO.includes(this.tlfExternoSelected))
        }

        if (this.hEntradaSelected != null && this.hEntradaSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.HORA_ENTRADA !== null && x.HORA_ENTRADA.includes(this.hEntradaSelected))
        }

        if (this.hSalidaSelected != null && this.hSalidaSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.HORA_SALIDA !== null && x.HORA_SALIDA.includes(this.hSalidaSelected))
        }

        if (this.organismoSelected && this.organismoSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.ID_ORGANISMO === this.organismoSelected.ID_ORGANISMO)
        }

        this.mediosFilter = mediosFilter.sort((a, b) => constants.collator.compare(a.MEDIO, b.MEDIO))
      } else {
        await new Promise((resolve, reject) => setTimeout(resolve, 500))
        this.filterMedios()
      }
    },

    baseItems () {
      let baseMedios = JSON.parse(JSON.stringify(this.bases))
      baseMedios = baseMedios.sort((a, b) => constants.collator.compare(a.NOMBRE, b.NOMBRE))
      return baseMedios
    },

    tiposItems () {
      let tipos = JSON.parse(JSON.stringify(this.tiposMedio))
      tipos = tipos.sort((a, b) => constants.collator.compare(a.TIPO, b.TIPO))
      return tipos
    },

    categoriaItems () {
      let categorias = JSON.parse(JSON.stringify(this.categoriasMedio))
      categorias = categorias.sort((a, b) => constants.collator.compare(a.CATEGORIA, b.CATEGORIA))
      return categorias
    },

    increaseFirstContainerHeight () {
      // Forzar una actualización del DOM para reflejar la nueva altura
      this.$nextTick(() => {
        this.calculateAvailableSpace()
      })
    },

    calculateAvailableSpace () {
      // Obtener la posición y altura del primer v-container
      const firstContainer = this.$refs.firstContainer
      const firstContainerRect = firstContainer.getBoundingClientRect()
      const firstContainerBottom = firstContainerRect.top + firstContainerRect.height

      // Obtener la altura del viewport
      const viewportHeight = window.innerHeight

      // Calcular el espacio disponible en vh
      this.availableSpace = ((viewportHeight - firstContainerBottom) / viewportHeight) * 100
    },

    resetFilters () {
      this.tableFilters = this.headers.filter(x => x.initialShow)
    },

    downloadExcelMedios () {
      ExcelHelper.downloadJSON2CSV(this.computedHeaders.filter(x => x.value !== 'ACCIONES'), this.mediosFilter)
    }

  },
  mounted () {
    this.organismoSelected = this.$store.getters['organismo/organismos'].find(x => x.ORGANISMO === 'INFOEX')
    this.filterMedios()
    this.maxVisible = this.headers.filter(x => x.initialShow).length - 1
    this.resetFilters()

    // Calcular el espacio disponible al montar el componente
    this.calculateAvailableSpace()
    // También puedes actualizar el espacio disponible si la ventana se redimensiona
    window.addEventListener('resize', this.calculateAvailableSpace)
  },
  beforeDestroy () {
    // Limpiar el event listener para evitar fugas de memoria
    window.removeEventListener('resize', this.calculateAvailableSpace)
  }

}
</script>

<style scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
}
.v-data-table {
  overflow: auto;
}
.v-data-table >>> .v-data-table__wrapper {
  /* Header fijo */
  overflow: unset;
}
/* .v-data-table >>> .v-data-footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: white;
  } */

  .no-spacing {
    margin: 0 !important;
    padding: 0 !important;
  }
</style>
