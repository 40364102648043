<template>
  <div id="buttonsMap">
    <!-- Centrar mapa -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('home')">
          <v-icon>esri-icon-home</v-icon>
        </v-btn>
      </template>
      <span>Centrar</span>
    </v-tooltip>

    <!-- Orientar norte
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('orientar')">
          <v-icon>esri-icon-compass</v-icon>
        </v-btn>
      </template>
      <span>Orientar</span>
    </v-tooltip>
-->
    <!-- Buscar coordenadas -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('searchCoords')">
          <v-icon>esri-icon-search</v-icon>
        </v-btn>
      </template>
      <span>Buscador coordenadas</span>
    </v-tooltip>

    <!-- Resumen medios -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('resumenMedios')" v-show="disableForExterno()">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <span>Resumen de medios</span>
    </v-tooltip>

    <!-- Capas -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('layerList')">
          <v-icon>esri-icon-layer-list</v-icon>
        </v-btn>
      </template>
      <span>Capas</span>
    </v-tooltip>

    <!-- Vista 3D -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn v-show="is2D" fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('3D')">
          <span class="text-button font-weight-bold">3D</span>
        </v-btn>
      </template>
      <span>Vista 3D</span>
    </v-tooltip>

    <!-- Vista 2D -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn v-show="!is2D" fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('2D')">
          <span class="text-button font-weight-bold">2D</span>
        </v-btn>
      </template>
      <span>Vista 2D</span>
    </v-tooltip>

    <!-- Mapas base -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-speed-dial v-model="sdBasemaps" direction="left" transition="slide-x-reverse-transition">
            <template #activator>
              <v-btn v-model="sdBasemaps" fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall">
                <v-icon v-if="sdBasemaps">mdi-close</v-icon>
                <v-icon v-else>esri-icon-basemap</v-icon>
              </v-btn>
            </template>

            <!-- Botones mapas base -->
            <v-tooltip bottom v-for="(basemap, i) in basemaps" :key="i">
              <template #activator="{ on2, attrs2 }">
                <!-- TODO: el tooltip hace que se rompa la animacion de cerrar -->
                <v-btn fab icon v-bind="attrs2" v-on="on2" color="transparent" @click="setBaseMap(basemap)">
                  <v-avatar>
                    <img :src="require(`@/assets/basemap/${basemap.icon}`)" height="100%" width="100%">
                  </v-avatar>
                </v-btn>
              </template>
              <span>{{ basemap.label }}</span>
            </v-tooltip>
          </v-speed-dial>
        </div>
      </template>
      <span>Cambiar mapa</span>
    </v-tooltip>

    <!-- Zoom+ -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-show="!$vuetify.breakpoint.mobile" v-bind="attrs" v-on="on" @click="eventButton('zoomIn')">
          <v-icon>esri-icon-zoom-in-magnifying-glass</v-icon>
        </v-btn>
      </template>
      <span>Ampliar zoom</span>
    </v-tooltip>

    <!-- Zoom- -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-show="!$vuetify.breakpoint.mobile" v-bind="attrs" v-on="on" @click="eventButton('zoomOut')">
          <v-icon>esri-icon-zoom-out-magnifying-glass</v-icon>
        </v-btn>
      </template>
      <span>Disminuir zoom</span>
    </v-tooltip>

    <!-- Compartir mapa -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" :color="shareMapButtonColor" @click="shareMap" v-show="disableForExterno()">
          <v-icon>mdi-monitor-share</v-icon>
        </v-btn>
      </template>
      <span>Compartir mapa</span>
    </v-tooltip>

    <!-- Dibujar compartir mapa -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-speed-dial v-model="sdGraficos" v-show="showBtnGraphics" direction="left" transition="slide-x-reverse-transition">
            <template #activator>
              <v-btn v-model="sdGraficos" fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall">
                <v-icon v-if="sdGraficos">mdi-close</v-icon>
                <v-icon v-else>esri-icon-edit</v-icon>
              </v-btn>
            </template>

            <!-- Botones graficos -->
            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="drawGraphicShareMap('point')">
                  <v-icon>esri-icon-blank-map-pin</v-icon>
                </v-btn>
              </template>
              <span>Punto</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="drawGraphicShareMap('multipoint')">
                  <v-icon>esri-icon-handle-horizontal</v-icon>
                </v-btn>
              </template>
              <span>Multi punto</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="drawGraphicShareMap('polyline')">
                  <v-icon>esri-icon-polyline</v-icon>
                </v-btn>
              </template>
              <span>Línea</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="drawGraphicShareMap('polygon')">
                  <v-icon>esri-icon-polygon</v-icon>
                </v-btn>
              </template>
              <span>Polígono</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="drawGraphicShareMap('reset')">
                  <v-icon>esri-icon-trash</v-icon>
                </v-btn>
              </template>
              <span>Borrar</span>
            </v-tooltip>
          </v-speed-dial>
        </div>
      </template>
      <span>Gráficos</span>
    </v-tooltip>

    <!-- Capturar mapa -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('captureMap')" v-show="disableForExterno()">
          <v-icon>esri-icon-media</v-icon>
        </v-btn>
      </template>
      <span>Capturar mapa</span>
    </v-tooltip>

    <!-- Medicion -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-speed-dial v-model="sdMedicion" direction="left" transition="slide-x-reverse-transition">
            <template #activator>
              <v-btn v-model="sdMedicion" fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall">
                <v-icon v-if="sdMedicion">mdi-close</v-icon>
                <v-icon v-else>esri-icon-measure</v-icon>
              </v-btn>
            </template>

            <!-- Botones graficos -->
            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="measure('distance')">
                  <v-icon>esri-icon-measure-line</v-icon>
                </v-btn>
              </template>
              <span>Medir distancia</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="measure('area')">
                  <v-icon>esri-icon-measure-area</v-icon>
                </v-btn>
              </template>
              <span>Medir área</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="clearMeasurement()">
                  <v-icon>esri-icon-trash</v-icon>
                </v-btn>
              </template>
              <span>Borrar medidas</span>
            </v-tooltip>
          </v-speed-dial>
        </div>
      </template>
      <span>Medir</span>
    </v-tooltip>

    <!-- Track area -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('drawHistoricoPolygon')" v-show="disableForExterno()">
          <v-icon>esri-icon-polygon</v-icon>
        </v-btn>
      </template>
      <span>Historico en un area</span>
    </v-tooltip>

    <!-- Simulacion
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('showDialogSimulacion')">
          <v-icon>mdi-pine-tree-fire</v-icon>
        </v-btn>
      </template>
      <span>Simulacion</span>
    </v-tooltip> -->

    <!-- Emergencias
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('showDialogEmergencias')">
          <v-icon>mdi-car-brake-alert</v-icon>
        </v-btn>
      </template>
      <span>Emergencias activas</span>
    </v-tooltip> -->

    <!-- Geofencing
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('showDialogGeofencing')">
          <v-icon>mdi-alert</v-icon>
        </v-btn>
      </template>
      <span>Geofencing</span>
    </v-tooltip> -->
    <vx-dialog-track-polygon :show="showDialogPolygonHistorico" @aceptar="startDrawHistoricoPolygon" @cancelar="cancelDialogHistoricoPolygono" />

    <!-- Compass widget -->
    <div id="compassWidget" class="compass" v-show="is3D" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import api from '@/api'
import * as ArcGIS from '../../helpers/ArcGIS'
import * as PermisosHelper from '../../helpers/PermisosHelper'
import DialogFechaTrackeMedioArea from './DialogFechaTrackeMedioArea.vue'

export default {
  data: () => ({
    is2D: true,

    sdBasemaps: false,
    sdGraficos: false,
    sdMedicion: false,
    sdPolygonAreaTrack: false,
    showDialogPolygonHistorico: false,
    mediosInPolygon: []
  }),

  components: {
    'vx-dialog-track-polygon': DialogFechaTrackeMedioArea
  },

  computed: {
    ...mapGetters('shareMap', [
      'isSharingMap',
      'requestShareMap'
    ]),

    ...mapGetters('map', [
      'paramsComunidad',
      'basemaps'
    ]),

    isButtonXSmall () {
      return this.$vuetify.breakpoint.name === 'md' || this.$vuetify.breakpoint.mobile
    },

    shareMapButtonColor () {
      return this.isSharingMap ? 'red' : 'white'
    },

    showBtnGraphics () {
      return this.isSharingMap && !this.requestShareMap
    },

    medios () {
      return this.$store.getters['medio/medios']
    },

    is3D () {
      return this.$store.getters['map/isMap3D']
    }

  },

  methods: {
    eventButton (event) {
      this.$log.debug('typeEventButton', event)

      switch (event) {
        case 'home':
          ArcGIS.setExtent(this.paramsComunidad.EXTENT)
          break
        // case 'orientar':
        //   ArcGIS.centerCompass()
        //   break
        case 'searchCoords':
          this.$emit('showSearchCoords')
          break
        case 'layerList':
          this.$emit('showLayerList')
          break
        case '3D':
          this.is2D = !this.is2D
          this.$store.dispatch('shareMap/activar2D', false)
          ArcGIS.use3D()
          break
        case '2D':
          this.is2D = !this.is2D
          this.$store.dispatch('shareMap/activar2D', true)
          ArcGIS.use2D()
          break
        case 'zoomIn':
          ArcGIS.setZoom(+1)
          break
        case 'zoomOut':
          ArcGIS.setZoom(-1)
          break
        case 'captureMap':
          this.captureMap()
          break
        case 'showDialogSimulacion':
          this.$emit('showDialogSimulacion')
          break
        case 'showDialogEmergencias':
          this.$emit('showDialogEmergencias')
          break

        case 'showDialogGeofencing':
          this.$emit('showDialogGeofencing')
          break
        case 'drawHistoricoPolygon':
          this.drawHistoricoPolygon()
          break
        case 'resumenMedios':
          this.$emit('showResumenMedios')
          break
        default:
          console.error('Boton desconocido:', event)
      }
    },

    changeMapView () {
      if (this.is2D) {
        this.eventButton('3D')
      } else {
        this.eventButton('2D')
      }

      this.is2D = !this.is2D
    },

    setBaseMap (basemap) {
      ArcGIS.setBaseMap(basemap)
    },

    shareMap () {
      // this.$emit('shareMap', !this.isSharingMap)
      this.$eventHub.$emit('shareMap', !this.isSharingMap)
    },

    drawGraphicShareMap (type) {
      let event = {
        function: type
      }

      ArcGIS.paintEventShareMap(event)
    },

    async captureMap () {
      let base64Img = await ArcGIS.captureMap()

      this.$root.$confirmDialog.open('¿Está seguro?', 'Enviar captura a Telegram').then(result => {
        if (result) {
          let msg = {
            image: base64Img.substr(22),
            user: this.$store.getters['usuario/currentUsuario'].USUARIO
          }

          api.others.sendImageTelegram(msg)
        }
      })
    },

    measure (type) {
      ArcGIS.measure(type)
    },

    clearMeasurement () {
      ArcGIS.clearMeasurement()
    },

    drawHistoricoPolygon () {
      if (!this.sdPolygonAreaTrack) {
        this.showDialogPolygonHistorico = true
      } else {
        this.sdPolygonAreaTrack = false

        for (let i = 0; i < this.mediosInPolygon.length; i++) {
          ArcGIS.hideTrack(this.mediosInPolygon[i])
        }

        this.mediosInPolygon = []
      }
    },

    async startDrawHistoricoPolygon (fecha1, fecha2) {
      this.showDialogPolygonHistorico = false
      this.sdPolygonAreaTrack = true

      this.$emit('showSnackBarDibujando')
      let poligono = await ArcGIS.drawPolygonAreaToTrackMedios()

      if (poligono) {
        this.$emit('showSnackBarDibujando')

        this.isLoadingTrack = true
        this.$emit('showSnackBarLoading')

        let data = {
          coordenadasPoligono: poligono,
          fechaInit: fecha1,
          fechaFin: fecha2
        }

        try {
          let response2 = await api.medio.getTrackFechasInPolygon(data)
          if (response2.status === 200) {
            for (let key in response2.data) {
              let posiciones = response2.data[key]
              let medio = this.medios.find(element => element.ID_MEDIO === key)
              this.mediosInPolygon.push(key)

              ArcGIS.drawTrack(key, medio.MEDIO, posiciones, false)
            }

            this.$emit('showSnackBarLoading')
          } else if (response2.status === 204) {
            this.$emit('showSnackBarLoading')
            this.$emit('showSnackBarError', 'No han actuado medios en este área en las fechas indicadas')
            this.sdPolygonAreaTrack = false
          }
        } catch (error) {
          this.$emit('showSnackBarError', 'Se produjo un error en el servidor')
          this.sdPolygonAreaTrack = false
        }
      } else {
        this.$emit('showSnackBarDibujando')
      }
    },
    cancelDialogHistoricoPolygono () {
      this.showDialogPolygonHistorico = false
    },
    cancelDrawingPolygon () {
      this.sdPolygonAreaTrack = false
      ArcGIS.cancelDrawingPolygon()
    },

    change2D (estado) {
      this.is2D = estado
    },

    disableForExterno () {
      return PermisosHelper.disableForExterno()
    }
  },

  mounted () {
    this.$eventHub.$on('change2D', this.change2D)
  }
}
</script>

<style scoped>
  #buttonsMap {
    position: absolute;
    top: 70px;
    right: 15px;
    /* overflow-y: auto; */
    height: calc(100% - 160px) !important; /* 26 px footer */
  }

  #buttonsMap button {
    display: block;
    margin-top: 7px;
  }

  .compass {
    margin-top: 7px;
  }

</style>
