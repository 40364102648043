import * as ArcGIS from './ArcGIS'
import constants from './constants'

import store from '@/store'

// const Q = require('q')

// TODO: IMPORTANTE -> VIS.JS no soporta nativamente DayJS asi que es necesario pasar las fechas en otro formato (string, unix, etc) por eso se usa .valueOf()
// ¿Quiza algun dia esto no haga falta?
export const convertDatesToUNIX = function (items) {
  let newItems = JSON.parse(JSON.stringify(items))

  /* for (let i = 0; i < newItems.length; i++) {
    newItems[i].start = newItems[i].start.valueOf()
    newItems[i].end = newItems[i].end.valueOf()
  } */
  for (let i = 0; i < newItems.length; i++) {
    let item = newItems[i]
    if (item.type !== 'background') {
      item.style = 'height: 30px'
    }
  }

  return newItems
}

/**
 * Devuelve una lista con los medios, su tipo, y el tiempo que tardan en llegar a las coordenadas
 */
export const calculateDistanceTimeToIncendio = function (medios, lat, lon) {
  let xy = ArcGIS.convertirLatLonToETRS89(lat, lon)
  let x1 = xy[0]
  let y1 = xy[1]

  let tiemposMedios = []

  for (let i = 0; i < medios.length; i++) {
    let m = medios[i]
    let x2 = m.X
    let y2 = m.Y

    let rumbo = Math.atan2(y1 - y2, x1 - x2) * 360 / 2 / Math.PI
    if (rumbo <= 90) {
      rumbo = 90 - rumbo
    } else rumbo = 450 - rumbo

    let distancia = (Math.sqrt(Math.pow(x1 - x2, 2)) + Math.sqrt(Math.pow(y1 - y2, 2))) / 1000

    let tiempo = distancia / m.VELOCIDAD_MAX
    let n = new Date(0, 0)
    n.setSeconds(tiempo * 60 * 60)

    let t = {
      ID_MEDIO: m.ID_MEDIO,
      ID_MEDIO_SECTOR: m.ID_MEDIO_SECTOR,
      MEDIO: m.MEDIO,
      ALIAS: m.ALIAS,
      TIPO: m.TIPO,
      HORA_SALIDA_BASE: m.HORA_SALIDA_BASE,
      /* horaLlegadaInc: m.horaLlegadaInc, // Puede ser null, solo tiene valor en los medios de planificacion real
      finActuacionInc: m.finActuacionInc, // Puede ser null, solo tiene valor en los medios de planificacion real
      horaLlegadaBase: m.horaLlegadaBase, // Puede ser null, solo tiene valor en los medios de planificacion real */
      TIEMPO_MAX_VUELO: m.TIEMPO_MAX_VUELO,
      FECHA_FIN_DESCANSO: m.FECHA_FIN_DESCANSO,
      FERRY_INCENDIO: n.toTimeString().slice(0, 5),
      PERIODOS_PLANIFICACION: m.PERIODOS_PLANIFICACION
    }

    // Si el medio vuelve a Agoncillo, calcular la distancia y el tiempo
    if (m.ID_BASE_REPOSTAJE_PLANIF) {
      let baseRepostaje = store.getters['medio/getBaseByID'](m.ID_BASE_REPOSTAJE_PLANIF)

      let distRepostaje = (Math.sqrt(Math.pow(x1 - baseRepostaje.X, 2)) + Math.sqrt(Math.pow(y1 - baseRepostaje.Y, 2))) / 1000

      let tiempoCaminoRepostaje = distRepostaje / m.VELOCIDAD_MAX
      let n = new Date(0, 0)
      n.setSeconds(tiempoCaminoRepostaje * 60 * 60)

      t.FERRY_REPOSTAJE = n.toTimeString().slice(0, 5)
      t.ID_BASE_REPOSTAJE_PLANIF = m.ID_BASE_REPOSTAJE_PLANIF
    }

    tiemposMedios.push(t)
  }

  return tiemposMedios
}

/**
 * Devuelve el objeto planificacion
 */
export const getAddMedioPlanificacion = function (medio, idSector, fechaAviso) { // TODO: Terminar este metodo y sustituir donde haga falta
  let idMedioSector = this.$uuid.createUUID()

  let tiempos = constants.tiemposTrabajoMedios
  if (medio.TIPO === 'Retén Tragsa' || medio.TIPO === 'Cuadrilla Helitransportada') {
    tiempos = constants.tiemposTrabajoMediosReducido
  }

  let fechaAvisoDate = this.$date.parseDate(fechaAviso)

  let inicioJornada = this.$date.parseDate(fechaAvisoDate.format('DD/MM/YYYY') + ' ' + medio.HORA_ENTRADA) // TODO: Y que pasa si el inicio jornada fue el dia antes?
  let planificacion = {
    ID_PLANIFICACION_MEDIO: this.$uuid.createUUID(),
    ID_MEDIO_SECTOR: idMedioSector,
    INICIO_JORNADA: inicioJornada,
    TIEMPO_OPERATIVO: tiempos.tiempoJornada,
    TIEMPO_DESCANSO: tiempos.tiempoDescanso
  }

  let medioSector = {
    ID_MEDIO_SECTOR: idMedioSector,
    ID_SECTOR: idSector,
    ID_MEDIO: medio.ID_MEDIO,
    FECHA_AVISO: fechaAvisoDate,
    PLANIFICACION: planificacion
  }

  return medioSector
}
