<template>
  <v-navigation-drawer
    ref="drawer"
    v-model="show"
    :width="navigation.width"
    color="#AAAAAACC"
    absolute
    hide-overlay
    stateless
    style="z-index: 2"
  >
    <v-card>
      <v-toolbar color="primary" dark dense>
        <v-btn dark icon @click="cerrar">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title>Simulaciones</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pa-0">
        <v-tabs
          v-model="tabActiva"
          background-color="primaryLight"
          dark
          slider-color="white"
        >
          <v-tab v-for="t in tabs" :key="t" ripple>
            {{ t }}
          </v-tab>
          <v-tab-item key="NuevaSimulacion" v-if="!disabledByRol">
            <v-card flat>
              <v-card-text>
                <v-form v-model="isValid">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="nombreSimulacion"
                          label="Nombre Simulacion"
                          prepend-icon="mdi-terrain"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <!-- <v-col sm="2" align="center">
                        <v-btn color="primaryLight" dark @click="initSketch('point')">Punto</v-btn>
                      </v-col>
                      <v-col sm="2" align="center">
                        <v-btn color="primaryLight" dark @click="initSketch('polyline')">Línea</v-btn>
                      </v-col>
                      <v-col sm="2" align="center">
                        <v-btn color="primaryLight" dark @click="initSketch('polygon')">Polígono</v-btn>
                      </v-col> -->
                      <v-tabs
                        v-model="tabActivaGraficos"
                        slider-color="primaryLight"
                      >
                        <v-tab v-for="t in tabsGraficos" :key="t" ripple>
                          {{ t }}
                        </v-tab>
                        <v-tab-item key="SeleccionManual">
                          <v-row class="pa-6">
                            <v-chip-group
                              selected-class="text-deep-purple-accent-4"
                              mandatory
                            >
                              <v-chip
                                v-for="item in esriButtons"
                                :key="item.id"
                                @click="initSketch(item.id)"
                              >
                                {{ item.label }}
                                <v-icon small class="pl-2">
                                  {{
                                    item.icon
                                  }}
                                </v-icon>
                              </v-chip>
                            </v-chip-group>
                          </v-row>
                        </v-tab-item>

                        <v-tab-item key="Coordenadas">
                          <v-row class="pa-4">
                            <v-col sm="4">
                              <v-text-field
                                v-model="latitud"
                                label="Latitud"
                                dense
                                outlined
                                prepend-icon="mdi-map-marker"
                                v-mask="'##.#####'"
                                hide-details
                              />
                            </v-col>

                            <v-col sm="4">
                              <v-text-field
                                v-model="longitud"
                                label="Longitud"
                                dense
                                outlined
                                v-mask="'-#.#####'"
                                hide-details
                              />
                              <!-- TODO: en alguna ocasion el - no sera obligatorio -->
                            </v-col>
                            <v-col sm="4" align="right">
                              <v-chip @click="addPunto" color="success">
                                Añadir
                                <v-icon small class="pl-2">mdi-plus</v-icon>
                              </v-chip>
                            </v-col>
                          </v-row>
                        </v-tab-item>
                      </v-tabs>
                    </v-row>

                    <v-data-table
                      v-show="focos.length !== 0"
                      :headers="[
                        { text: 'Nombre', sortable: false },
                        { text: 'Tipo', sortable: false },
                        { text: 'Eliminar', sortable: false },
                      ]"
                      :items="focos"
                      hide-default-footer
                      disable-pagination
                    >
                      <template #item="{ item }">
                        <tr>
                          <td class="text-xs-left">Foco {{ item.id }}</td>
                          <td class="text-xs-left">{{ item.tipo }}</td>
                          <td>
                            <v-btn icon small @click="eliminarFoco(item)">
                              <v-icon small color="error">
                                esri-icon-trash
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>

                    <v-row class="pt-2" dense v-show="focos.length !== 0">
                      <v-col align="center">
                        <v-btn rounded small @click="eliminarAllFocos()">
                          <v-icon small color="error">esri-icon-trash</v-icon>
                          Eliminar todo
                        </v-btn>
                      </v-col>
                    </v-row>

                    <!-- <v-row>
                       <v-text-field v-model="geometria" prepend-icon="esri-icon-map-pin" readonly clearable label="Geometría" :rules="[rules.required]" required/>
                      <p>{{ textoGeometria }}</p>
                    </v-row> -->

                    <v-row dense>
                      <v-col>
                        <p class="text-h6 black--text">
                          <v-icon>esri-icon-calendar</v-icon>
                          Seleccion temporal
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="pl-6" dense>
                      <v-icon small class="pa-3">mdi-calendar-edit</v-icon>
                      <v-col>
                        <vx-date-text-edit
                          v-model="fechaInicio"
                          label="Fecha inicio"
                          required
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="numHoras"
                          @input="numHoras = parseInt(numHoras)"
                          label="Núm. horas"
                          type="number"
                          maxlength="2"
                          :rules="rulesNumHoras"
                        />
                      </v-col>

                      <v-col>
                        <vx-date-text-edit
                          v-model="fechaFin"
                          label="Fecha fin"
                          readonly
                          disabled
                        />
                      </v-col>
                    </v-row>

                    <v-row class="pl-6">
                      <v-icon small class="pa-3">mdi-clock</v-icon>
                      <v-text-field
                        v-model="intervaloTiempo"
                        label="Intervalo de tiempo (min)"
                        type="number"
                        maxlength="3"
                        :rules="rulesIntervaloTiempo"
                      />
                    </v-row>

                    <v-row dense>
                      <v-col>
                        <p class="text-h6 black--text">
                          <v-icon>mdi-weather-partly-cloudy</v-icon>
                          Meteorologia
                        </p>
                      </v-col>
                    </v-row>
                    <v-row dense class="pl-6">
                      <v-checkbox label="Meteorología personalizada" v-model="useCustomMeteo" />
                      <!-- <v-radio-group v-model="useCustomMeteo">
                        <v-radio
                          class="pl-3"
                          label="Predicción de meteorología existente"
                          value="0"
                        />
                        <v-radio
                          class="pa-3"
                          label="Meteorología personalizada"
                          value="1"
                        />
                      </v-radio-group> -->
                    </v-row>

                    <div v-show="useCustomMeteo">
                      <v-btn
                        class="my-3"
                        :loading="downloadingMeteo"
                        :disabled="!geometria || !fechaInicio"
                        @click="downloadMeteo"
                      >
                        <v-icon>mdi-download</v-icon>
                        Descargar datos
                      </v-btn>

                      <v-data-table
                        :headers="meteoHeaders"
                        :items="meteoData"
                        hide-default-footer
                        disable-pagination
                      >
                        <template #item="{ item }">
                          <tr>
                            <td class="text-xs-left">{{ item.VARIABLE }}</td>
                            <td v-for="(n, i) in parseInt(numHoras+2)" :key="i">
                              <v-edit-dialog
                                :return-value.sync="item.VALORES[i]"
                                lazy
                              >
                                {{ item.VALORES[i] }}
                                <template #input>
                                  <v-text-field
                                    v-model="item.VALORES[i]"
                                    label="Editar"
                                    type="number"
                                    :rules="getMeteoRules(item.ID)"
                                  />
                                </template>
                              </v-edit-dialog>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <v-row dense>
                      <v-col>
                        <p class="text-h6 black--text">
                          <v-icon>mdi-tune</v-icon>
                          Opciones
                        </p>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col class="pl-6" dense>
                        <v-checkbox label="Activar vientos locales" v-model="vientosLocales" />
                        <v-checkbox
                          label="Seleccionar manualmente la humedad de combustible vivo"
                          v-model="showHumedad"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="pl-6" v-show="showHumedad">
                      <v-icon class="pl-3">mdi-water-percent</v-icon>
                      <v-col>
                        <v-text-field
                          v-model="humedadCombustible"
                          label="Humedad comb. (%)"
                          type="number"
                          maxlength="3"
                          :rules="rulesHumedad"
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="humedadVegetacion"
                          label="Humedad veg. (%)"
                          type="number"
                          maxlength="3"
                          :rules="rulesHumedad"
                        />
                      </v-col>

                      <!-- <v-col>
                        <v-select v-model="metodoCorona" :items="metodosCorona" label="Método corona" single-line autocomplete :rules="[rules.required]" required/>
                      </v-col>-->
                    </v-row>
                    <v-row dense>
                      <p class="text-h6 black--text">
                        <v-icon>mdi-fire-off</v-icon>
                        Modificar modelos de combustible
                      </p>
                    </v-row>
                    <v-row class="pt-2 pl-6">
                      <v-col>
                        <v-select
                          label="Modelo"
                          :items="combustibles"
                          item-text="MODELO"
                          item-value="MODELO"
                          v-model="modelo"
                          return-object
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col>
                        <v-btn
                          rounded
                          size="x-large"
                          :disabled="modelo === null"
                          @click="initModeloCombustible('polygon')"
                        >
                          Seleccionar area
                          <v-icon small class="pl-2">esri-icon-polygon</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-data-table
                      v-show="areasDeCombustibles.length !== 0"
                      :headers="[
                        { text: 'Nombre', sortable: false },
                        { text: 'Numero de modelo', sortable: false },
                        { text: 'Eliminar', sortable: false },
                      ]"
                      :items="areasDeCombustibles"
                      hide-default-footer
                      disable-pagination
                    >
                      <template #item="{ item }">
                        <tr>
                          <td class="text-xs-left">
                            Area de combustible {{ item.id }}
                          </td>
                          <td>Modelo {{ item.modelo }}</td>
                          <td>
                            <v-btn
                              icon
                              small
                              @click="eliminarModeloCombustible(item)"
                            >
                              <v-icon small color="error">
                                esri-icon-trash
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-row
                      class="pt-2"
                      dense
                      v-show="areasDeCombustibles.length !== 0"
                    >
                      <v-col align="center">
                        <v-btn
                          rounded
                          small
                          @click="eliminarAllModelosCombustible()"
                        >
                          <v-icon small color="error">esri-icon-trash</v-icon>
                          Eliminar todo
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <p class="text-h6 black--text">
                        <v-icon>mdi-fire-off</v-icon>
                        Añadir zonas no combustibles
                      </p>
                    </v-row>
                    <v-row class="pt-2">
                      <v-col class="pl-6">
                        <v-btn
                          rounded
                          size="x-large"
                          @click="initZonasNoCombustibles('polyline')"
                        >
                          Cortafuegos
                          <v-icon small class="pl-2">esri-icon-polyline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col align="center">
                        <v-btn
                          rounded
                          size="x-large"
                          @click="initZonasNoCombustibles('polygon')"
                        >
                          Area no combustible
                          <v-icon small class="pl-2">esri-icon-polygon</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-data-table
                      v-show="zonasNoCombustibles.length !== 0"
                      :headers="[
                        { text: 'Nombre', sortable: false },
                        { text: 'Eliminar', sortable: false },
                      ]"
                      :items="zonasNoCombustibles"
                      hide-default-footer
                      disable-pagination
                    >
                      <template #item="{ item }">
                        <tr>
                          <td class="text-xs-left">
                            {{ item.tipo }} {{ item.id }}
                          </td>
                          <td>
                            <v-btn
                              icon
                              small
                              @click="eliminarZonaNoCombustible(item)"
                            >
                              <v-icon small color="error">
                                esri-icon-trash
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-row
                      class="pt-2"
                      dense
                      v-show="zonasNoCombustibles.length !== 0"
                    >
                      <v-col align="center">
                        <v-btn
                          rounded
                          small
                          @click="eliminarAllZonasNoCombustibles()"
                        >
                          <v-icon small color="error">esri-icon-trash</v-icon>
                          Eliminar todo
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row class="pt-2">
                      <v-col>
                        <v-textarea
                          v-model="observaciones"
                          label="Observaciones"
                          dense
                          outlined
                          prepend-icon="esri-icon-comment"
                          auto-grow
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn text color="red" @click="resetCampos">Reset</v-btn>
                <v-btn
                  text
                  color="green"
                  @click="simular"
                  :disabled="!isValid || !geometria"
                >
                  Simular
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <!-- ------------------Tab lista------------------------ -->
          <v-tab-item key="ListaSimulaciones">
            <v-card>
              <v-card-text>
                <v-form>
                  <v-container>
                    <v-row align="center" class="pl-6">
                      <v-col>
                        <vx-date-text-edit
                          v-model="fechaDesde"
                          label="Desde"
                          icon="esri-icon-calendar"
                          only-date
                          :max="fechaHasta"
                          not-future
                          required
                        />
                      </v-col>
                      <v-col>
                        <vx-date-text-edit
                          v-model="fechaHasta"
                          label="Hasta"
                          icon="esri-icon-calendar"
                          only-date
                          :min="fechaDesde"
                          not-future
                          required
                        />
                      </v-col>
                      <v-col>
                        <v-btn
                          icon
                          @click="refreshSimulaciones"
                          :loading="isLoadingSimulaciones"
                        >
                          <v-icon>mdi-reload</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-data-table
                          :headers="headers"
                          :items="simulaciones"
                          fixed-header
                          sort-by="FECHA_CREACION"
                          sort-desc
                          disable-pagination
                          hide-default-footer
                        >
                          <template #no-data>
                            <p>Datos no disponibles.</p>
                          </template>

                          <template #item="{ item }">
                            <tr>
                              <td>{{ item.NOMBRE ? item.NOMBRE : 'Sin nombre' }}</td>
                              <td>{{ item.FECHA_CREACION | formatDate }}</td>
                              <td>{{ item.FECHA_INICIO | formatDate }}</td>
                              <td>{{ item.NUM_HORAS }}h</td>
                              <td>{{ item.INTERVALO }} min</td>
                              <v-container fluid>
                                <!-- Pintar simulacion -->
                                <td class="px-1">
                                  <v-tooltip bottom>
                                    <template #activator="{ on }">
                                      <v-btn
                                        v-on="on"
                                        icon
                                        x-small
                                        @click="paintSimulacion(item)"
                                      >
                                        <v-icon color="black">mdi-eye</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Visualizar</span>
                                  </v-tooltip>
                                </td>
                                <!-- Exportar simulacion -->
                                <td class="px-1">
                                  <v-menu bottom right>
                                    <template #activator="{ on: onMenu }">
                                      <v-tooltip bottom>
                                        <template #activator="{ on: onTooltip }">
                                          <v-btn icon v-on="{ ...onMenu, ...onTooltip }" x-small>
                                            <v-icon color="black">mdi-download</v-icon>
                                          </v-btn>
                                        </template>
                                        <span>Descargar como...</span>
                                      </v-tooltip>
                                    </template>
                                    <v-list>
                                      <v-list-item @click="descargarSimulacionSHP(item)">
                                        <v-list-item-title>Descargar SHP</v-list-item-title>
                                      </v-list-item>
                                      <v-list-item @click="descargarSimulacionPDF(item)">
                                        <v-list-item-title>Descargar PDF</v-list-item-title>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </td>
                                <!-- Eliminar simulacion -->
                                <td class="px-1">
                                  <v-tooltip bottom>
                                    <template #activator="{ on }">
                                      <v-btn
                                        v-on="on"
                                        icon
                                        x-small
                                        @click="deleteSimulacion(item)"
                                      >
                                        <v-icon color="red">mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Eliminar</span>
                                  </v-tooltip>
                                </td>
                              </v-container>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Snackbar loading -->
    <v-snackbar v-model="isLoadingRequest" bottom :timeout="-1">
      Enviando petición de simulación...
      <template #action="{ attrs }">
        <v-progress-circular v-on="attrs" indeterminate />
      </template>
    </v-snackbar>

    <!-- Snackbar error y success -->
    <v-snackbar
      v-model="showSnackbar"
      :color="snackbarColor"
      :top="true"
      :timeout="3000"
    >
      <v-icon>mdi-alert</v-icon>
      {{ snackbarText }}
    </v-snackbar>
  </v-navigation-drawer>
</template>

<script>
import { mapActions } from 'vuex'
import api from '../../../api'
import * as ArcGIS from '../../../helpers/ArcGIS'
import * as PDFHelper from '../../../helpers/PDFHelper'

export default {
  props: {
    show: Boolean
  },

  data: () => ({
    simulacionSelected: null,
    nombreSimulacion: null,
    observaciones: null,
    isValid: false,
    sim: null,

    rulesNumHoras: [
      (v) => !!v || 'Campo obligatorio.',
      (v) => (!!v && v >= 4 && v <= 12) || 'Núm. inválido.'
    ],
    rulesHumedad: [
      (v) => !!v || 'Campo obligatorio.',
      (v) => (!!v && v >= 20 && v <= 300) || 'Núm. inválido.'
    ],
    rulesIntervaloTiempo: [
      (v) => !!v || 'Campo obligatorio.',
      (v) => (!!v && v >= 15 && v <= 240) || 'Núm. inválido.'
    ],

    tabsGraficos: ['Selección Manual', 'Coordenadas'],
    tabActivaGraficos: null,
    tabActiva: null,

    // Coordenadas
    latitud: null,
    longitud: null,

    // Graficos
    listGraphics: [],

    // Focos de incendio
    focos: [],

    // Nueva simulacion
    isLoadingRequest: false,

    showSnackbar: false,
    snackbarColor: 'green',
    snackbarText: '',

    tipoGeometria: null,
    geometria: null,
    fechaInicio: null,
    numHoras: 12,
    humedadCombustible: 50,
    humedadVegetacion: 50,
    metodosCorona: ['Metodo1', 'Metodo2'],
    metodoCorona: null,
    intervaloTiempo: 15,

    // Meteo
    vientosLocales: false,
    showHumedad: false,
    useCustomMeteo: false,
    downloadingMeteo: false,
    humedadLeñoso: 10,
    humedadHerbaceo: 10,
    meteoData: [
      { ID: 'temperatura', VARIABLE: 'Temperatura', VALORES: [] },
      { ID: 'humedad_relativa', VARIABLE: 'Hum. Relativa (%)', VALORES: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20] },
      { ID: 'nubosidad', VARIABLE: 'Nubosidad (%)', VALORES: [] },
      { ID: 'mod_viento', VARIABLE: 'Mod. Viento', VALORES: [] },
      { ID: 'dir_viento', VARIABLE: 'Dir. Viento', VALORES: [] },
      { ID: 'precipitaciones', VARIABLE: 'Precipitaciones (mm)', VALORES: [] }
    ],

    // Listado simulaciones
    isLoadingSimulaciones: false,
    fechaDesde: null,
    fechaHasta: null,
    fechaFin: null,
    headers: [
      {
        text: 'Nombre Simulación',
        value: 'NOMBRE',
        align: 'left',
        sortable: false
      },
      {
        text: 'Fecha creación',
        value: 'FECHA_CREACION',
        align: 'left',
        sortable: true
      },
      {
        text: 'Fecha de inicio',
        value: 'FECHA_INICIO',
        align: 'left',
        sortable: true
      },
      {
        text: 'Num. Horas',
        value: 'NUM_HORAS',
        align: 'left',
        sortable: false
      },
      { text: 'Intervalo', value: 'INTERVALO', align: 'left', sortable: false },
      { text: '', value: 'ACCIONES', align: 'right', sortable: false }
    ],
    graphics: [],

    esriButtons: [
      { id: 'point', label: 'Punto', icon: 'esri-icon-radio-checked' },
      { id: 'polyline', label: 'Línea', icon: 'esri-icon-polyline' },
      { id: 'polygon', label: 'Polígono', icon: 'esri-icon-polygon' }
    ],

    // Variables modelos combustibles
    combustibles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],

    areasDeCombustibles: [],

    combustible: null,
    modelo: null,

    // Variables zonas no combustibles
    zonasNoCombustibles: [],

    zonaNoCombustible: null,

    sketchIniciado: false,
    navigation: {
      width: 550,
      borderSize: 3,
      minWidth: 550
    }

  }),

  watch: {
    show () {
      if (this.show && !this.geometria) {
        this.fechaInicio = this.$date.currentDate()
        this.numHoras = 8

        this.fechaHasta = this.$date.now().format('DD/MM/YYYY')
        this.fechaDesde = this.$date
          .now()
          .subtract(7, 'days')
          .format('DD/MM/YYYY')
        this.refreshSimulaciones()
      }
    },

    fechaInicio () {
      if (this.fechaInicio) {
        let fecha = this.$date
          .parseDate(this.fechaInicio, 'DD/MM/YYYY HH:mm')
          .add(this.numHoras, 'hours')

        if (fecha.isValid()) {
          this.fechaFin = fecha.format('DD/MM/YYYY HH:mm')
        }
      }
    },
    fechaFin () {
      if (this.fechaInicio) {
        let fecha = this.$date
          .parseDate(this.fechaInicio, 'DD/MM/YYYY HH:mm')
          .add(this.numHoras, 'hours')

        if (fecha.isValid()) {
          this.fechaFin = fecha.format('DD/MM/YYYY HH:mm')
        }
      }
    },

    numHoras () {
      for (let i = 0; i < this.meteoData.length; i++) {
        // this.meteoData[i].VALORES = new Array(parseInt(this.numHoras))
        let prevData = JSON.parse(JSON.stringify(this.meteoData[i].VALORES))
        this.meteoData[i].VALORES = []
        for (let j = 0; j < this.numHoras + 2; j++) {
          this.meteoData[i].VALORES.push(prevData[j] ? prevData[j] : 0)
        }
      }

      let fecha = this.$date
        .parseDate(this.fechaInicio, 'DD/MM/YYYY HH:mm')
        .add(this.numHoras, 'hours')

      this.fechaFin = fecha.format('DD/MM/YYYY HH:mm')
    }

    // longitudLlama () {
    //   this.setVisibilityLayer({
    //     id: 'layer-longitud-llama',
    //     visible: this.longitudLlama
    //     // item: this.item
    //   })
  },

  computed: {
    simulaciones () {
      return this.$store.getters['simulacion/simulaciones']
    },

    textoGeometria () {
      let txt = ''

      if (this.tipoGeometria !== null && this.geometria) {
        let numPuntos = this.geometria.length
        switch (this.tipoGeometria) {
          case 0:
            txt += 'Punto ' + this.geometria.map((x) => Math.trunc(x))
            break
          case 1:
            txt += 'Línea - ' + numPuntos + ' puntos'
            break
          case 2:
            txt += 'Polígono - ' + (numPuntos - 1) + ' puntos'
            break
        }
      }

      return txt
    },

    meteoHeaders () {
      let headers = []

      headers.push({ text: 'Variables', sortable: false, value: 'VARIABLE' })
      for (let i = -1; i < this.numHoras + 1; i++) {
        let headerName = (i < 10 && i >= 0) ? '0' + i : i
        headerName += 'h'
        headers.push({ text: headerName, sortable: false, value: i })
      }

      return headers
    },
    tabs () {
      if (this.disabledByRol) {
        return ['Lista de simulaciones']
      }
      return ['Nueva simulación', 'Lista de simulaciones']
    },
    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    }

    // fechaFinComp () {
    //   return this.fechaFin
    // }
  },

  methods: {
    ...mapActions('map', [
      'setVisibilityLayer',
      'setOpacityLayer'
    ]),
    // #region NUEVA SIMULACION
    initSketch (evt) {
      this.geometria = null
      this.$emit('close')

      switch (evt) {
        case 'point':
          this.tipoGeometria = 0
          break
        case 'polyline':
          this.tipoGeometria = 1
          break
        case 'polygon':
          this.tipoGeometria = 2
          break
      }

      let data = {
        categoria: 'foco',
        event: evt,
        callback: this.callbackGeometryDone
      }

      if (this.sketchIniciado) {
        this.$emit('paintSimulationSketch', data)
      } else {
        this.$emit('initSimulacionSketch', data)
      }
    },

    callbackGeometryDone (geometry) {
      this.$emit('stopSimulacionSketch') // Cerrar snackbar y parar sketchview
      this.sketchIniciado = true
      this.geometria = geometry.coordinates

      let tipo = null

      switch (this.tipoGeometria) {
        case 0:
          tipo = 'Punto'
          break
        case 1:
          tipo = 'Linea'
          break
        case 2:
          tipo = 'Área'
          break
      }

      let latLon = ArcGIS.convertirETRS89ToLatLon(
        geometry.coordinates[0],
        geometry.coordinates[1]
      )

      let foco = {
        id:
          this.focos.length !== 0
            ? this.focos[this.focos.length - 1].id + 1
            : 0,
        latitud: latLon[1].toFixed(5),
        longitud: latLon[0].toFixed(5),
        tipo: tipo,
        categoria: 'foco',
        geometry: geometry
      }

      this.focos.push(foco)
    },

    addPunto () {
      this.foco = {
        id:
          this.focos.length !== 0
            ? this.focos[this.focos.length - 1].id + 1
            : 0,
        latitud: this.latitud,
        longitud: this.longitud,
        tipo: 'Punto',
        categoria: 'foco',
        geometry: null
      }

      let data = {
        categoria: 'foco',
        event: 'point',
        callback: this.callbackAddPuntoDone,
        latitude: this.latitud,
        longitude: this.longitud
      }

      this.$emit('addPointSimulation', data)
    },

    callbackAddPuntoDone (geometry) {
      // this.$emit('stopSimulacionSketch') // Cerrar snackbar y parar sketchview
      this.sketchIniciado = true
      this.foco.geometry = geometry
      this.focos.push(this.foco)
    },

    eliminarFoco (foco) {
      let index = this.focos.indexOf(foco)
      this.focos.splice(index, 1)

      this.repintarAll()
    },

    eliminarAllFocos () {
      this.focos = []
      this.repintarAll()
    },

    // Zona modelos combustibles
    initModeloCombustible (evt) {
      this.$emit('close')

      let idAux =
        this.areasDeCombustibles.length !== 0
          ? this.areasDeCombustibles[this.areasDeCombustibles.length - 1].id + 1
          : 0

      this.combustible = {
        id: idAux,
        geometry: null,
        categoria: 'combustible',
        modelo: this.modelo
      }

      let data = {
        categoria: 'combustible',
        event: evt,
        callback: this.callbackModeloCombustibleDone
      }

      if (this.sketchIniciado) {
        this.$emit('paintSimulationSketch', data)
      } else {
        this.$emit('initSimulacionSketch', data)
      }
    },

    callbackModeloCombustibleDone (geometry) {
      this.$emit('stopSimulacionSketch') // Cerrar snackbar y parar sketchview
      this.sketchIniciado = true
      this.combustible.geometry = geometry

      this.areasDeCombustibles.push(this.combustible)
    },

    eliminarModeloCombustible (modelo) {
      let index = this.areasDeCombustibles.indexOf(modelo)
      this.areasDeCombustibles.splice(index, 1)

      this.repintarAll()
    },

    eliminarAllModelosCombustible () {
      this.areasDeCombustibles = []
      this.repintarAll()
    },

    // Zona de zonas no combustibles
    initZonasNoCombustibles (evt) {
      this.$emit('close')

      let idAux =
        this.zonasNoCombustibles.length !== 0
          ? this.zonasNoCombustibles[this.zonasNoCombustibles.length - 1].id + 1
          : 0

      this.zonaNoCombustible = {
        id: idAux,
        geometry: null,
        tipo: evt === 'polyline' ? 'Cortafuegos' : 'Zona no combustible',
        categoria: 'zonaNoCombustible'
      }

      let data = {
        categoria: 'zonaNoCombustible',
        event: evt,
        callback: this.callbackZonaNoCombustibleDone
      }

      if (this.sketchIniciado) {
        this.$emit('paintSimulationSketch', data)
      } else {
        this.$emit('initSimulacionSketch', data)
      }
    },

    callbackZonaNoCombustibleDone (geometry) {
      this.$emit('stopSimulacionSketch') // Cerrar snackbar y parar sketchview
      this.sketchIniciado = true
      this.zonaNoCombustible.geometry = geometry
      this.zonasNoCombustibles.push(this.zonaNoCombustible)
    },

    eliminarZonaNoCombustible (modelo) {
      let index = this.zonasNoCombustibles.indexOf(modelo)
      this.zonasNoCombustibles.splice(index, 1)
      this.repintarAll()
    },

    eliminarAllZonasNoCombustibles () {
      this.zonasNoCombustibles = []
      this.repintarAll()
    },

    repintarAll () {
      this.$emit('endSimulacionSketch')
      this.$emit('redrawSimulationElements', this.focos)
      this.$emit('redrawSimulationElements', this.zonasNoCombustibles)
      this.$emit('redrawSimulationElements', this.areasDeCombustibles)
    },

    getMeteoRules (idVariable) {
      let rules = []

      rules.push((v) => !!v || 'Campo obligatorio')
      switch (idVariable) {
        case 'temperatura':
          rules.push((v) => (!!v && v >= -60 && v <= 60) || 'Núm. inválido.')
          break
        case 'humedad_relativa':
        case 'nubosidad':
          rules = JSON.parse(JSON.stringify(this.rulesHumedad))
          break
        case 'mod_viento':
          rules.push((v) => (!!v && v >= 0) || 'Núm. inválido.')
          break
        case 'dir_viento':
          rules.push((v) => (!!v && v >= 0 && v <= 360) || 'Núm. inválido.')
          break
      }

      return rules
    },

    async downloadMeteo () {
      if (this.geometria) {
        this.downloadingMeteo = true

        let x, y
        if (this.tipoGeometria === 0) {
          x = this.geometria[0]
          y = this.geometria[1]
        } else {
          let p = this.geometria[0]
          x = p[0]
          y = p[1]
        }

        let latLon = ArcGIS.convertirETRS89ToLatLon(x, y)

        let params = {
          x: latLon[0],
          y: latLon[1],
          fecha: this.$date
            .parseDate(this.fechaInicio, 'DD/MM/YYYY HH:mm')
            .format('YYYYMMDDHHmm'),
          numHoras: this.numHoras
        }
        try {
          let response = await api.simulacion.getMeteo(params)
          let datos = response.data

          for (let i = 0; i < datos.length; i++) {
            let p = datos[i]

            p.VALORES = p.VALORES.map((x) => Math.round(x * 100) / 100)

            switch (p.ID) {
              case 'temperatura':
                this.meteoData[0].VALORES = p.VALORES
                break
              case 'humedad':
                this.meteoData[1].VALORES = p.VALORES
                break
              case 'nubosidad':
                this.meteoData[2].VALORES = p.VALORES
                break
              case 'viento':
                this.meteoData[3].VALORES = p.VALORES
                break
              case 'direccionViento':
                this.meteoData[4].VALORES = p.VALORES
                break
              case 'precipitacion':
                this.meteoData[5].VALORES = p.VALORES
                break
            }
          }
        } catch (err) {
          console.error(err)
          this.snackbarError('Error descargando los datos de meteo')
        } finally {
          this.downloadingMeteo = false
        }
      } else {
        this.snackbarError('Dibuje la geometría de inicio')
      }
    },

    resetCampos () {
      this.$emit('endSimulacionSketch')
      this.geometria = null
      this.sim = null
      this.fechaInicio = this.$date.currentDate()
      this.numHoras = 8

      // Coordenadas
      this.latitud = null
      this.longitud = null

      // Graficos
      this.listGraphics = []

      // Focos de incendio
      this.focos = []

      this.areasDeCombustibles = []

      this.combustible = null
      this.modelo = null

      // Variables zonas no combustibles
      this.zonasNoCombustibles = []

      this.zonaNoCombustible = null

      this.sketchIniciado = false

      this.humedadCombustible = 50
      this.humedadVegetacion = 50
      this.metodoCorona = null

      this.intervaloTiempo = 15

      this.humedadLeñoso = 10
      this.humedadHerbaceo = 10

      this.vientosLocales = false
      this.showHumedad = false
      this.useCustomMeteo = false

      for (let i = 0; i < this.meteoData.length; i++) {
        this.meteoData[i].VALORES = []
        for (let j = 0; j < this.numHoras + 2; j++) {
          if (this.meteoData[i].ID === 'humedad_relativa') {
            this.meteoData[i].VALORES.push(20)
          } else {
            this.meteoData[i].VALORES.push(0)
          }
        }
      }
    },

    async simular () {
      this.isLoadingRequest = true

      let graficos = []

      let meteoData = JSON.parse(JSON.stringify(this.meteoData))

      for (let i = 0; i < meteoData.length; i++) {
        // Convertir valores a numero
        let d = meteoData[i]
        d.VALORES = d.VALORES.map((x) => parseFloat(x))
      }

      let precipValores = []

      for (let i = 0; i < this.numHoras + 2; i++) {
        precipValores.push(0)
      }
      meteoData.push({
        ID: 'precipitacion',
        VARIABLE: 'Precipitación',
        VALORES: precipValores
      })

      // Geometrias de los focos
      let geometrias = []

      let tipoGeometrias = []

      for (let i = 0; i < this.focos.length; i++) {
        if (this.focos[i].tipo === 'Punto') {
          geometrias.push([this.focos[i].geometry.coordinates])
        } else {
          geometrias.push(this.focos[i].geometry.coordinates)
        }

        let tipo

        switch (this.focos[i].tipo) {
          case 'Punto':
            tipo = 0
            break
          case 'Linea':
            tipo = 1
            break
          case 'Área':
            tipo = 2
            break
        }

        tipoGeometrias.push(tipo)
      }

      // Geometrias de los cortafuegos
      let geometriasCortafuegos = []

      let tipoGeometriasCortaFuegos = []

      for (let i = 0; i < this.zonasNoCombustibles.length; i++) {
        geometriasCortafuegos.push(this.zonasNoCombustibles[i].geometry.coordinates)

        let tipo

        switch (this.zonasNoCombustibles[i].geometry.type) {
          case 'polyline':
            tipo = 1
            break
          case 'polygon':
            tipo = 2
            break
        }

        tipoGeometriasCortaFuegos.push(tipo)

        graficos.push({
          GEOMETRIA: this.zonasNoCombustibles[i].geometry.coordinates,
          TIPO_GEOMETRIA: this.zonasNoCombustibles[i].geometry.type,
          TIPO_GRAFICO: 'zonaNoCombustible'
        })
      }

      // Geometrias de los modelos de combustible
      let geometriasModeloComb = []

      let tiposModelosComb = []

      for (let i = 0; i < this.areasDeCombustibles.length; i++) {
        geometriasModeloComb.push(this.areasDeCombustibles[i].geometry.coordinates)

        let tipo = this.areasDeCombustibles[i].modelo

        tiposModelosComb.push(tipo)

        graficos.push({
          GEOMETRIA: this.areasDeCombustibles[i].geometry.coordinates,
          TIPO_GEOMETRIA: 'polygon',
          TIPO_MODELO_COMBUSTIBLE: tipo,
          TIPO_GRAFICO: 'combustible'
        })
      }

      // No funcionan los minutos en la simulacion asi que hay que ponerlos a 00
      this.fechaInicio = this.fechaInicio.substr(0, 14) + '00'
      this.fechaFin = this.fechaFin.substr(0, 14) + '00'

      // Creamos el objeto que pasar al simulador
      let datosSimulador = {
        geometry: geometrias,
        typeGeometry: tipoGeometrias,
        dateInit: this.$date // POR AHORA SOLO SE PUEDE A LAS HH:00
          .parseDate(this.fechaInicio, 'DD/MM/YYYY HH:mm')
          .format('YYYY_MM_DD_HH_mm'),
        dateEnd: this.$date
          .parseDate(this.fechaFin, 'DD/MM/YYYY HH:mm')
          .format('YYYY_MM_DD_HH_mm'),
        timeStep: this.intervaloTiempo,
        useCustomMeteo: this.useCustomMeteo ? 1 : 0,
        meteoData: meteoData,
        useWindNinja: this.vientosLocales ? 1 : 0,
        cortaFuegosSi: geometriasCortafuegos.length === 0 ? 0 : 1,
        geometryBarrier: geometriasCortafuegos,
        typeGeometryBarrier: tipoGeometriasCortaFuegos,
        liveWoodUser: parseInt(this.humedadCombustible),
        liveHerbUser: parseInt(this.humedadVegetacion),
        useCustomFuelMoisture: this.showHumedad ? 1 : 0,
        useFuelModelPoligon: tiposModelosComb.length === 0 ? 0 : 1,
        valueModCombUser: tiposModelosComb.length === 0 ? 0 : tiposModelosComb,
        geometryFuelModel: geometriasModeloComb.length === 0 ? 0 : geometriasModeloComb
      }

      // Creamos el objeto para insertar en BDD
      let datosBackend = {
        FECHA_CREACION: this.$date.now(),
        FECHA_INICIO: this.$date.parseDate(
          this.fechaInicio,
          'DD/MM/YYYY HH:mm'
        ),
        NUM_HORAS: this.numHoras,
        INTERVALO: this.intervaloTiempo,
        NOMBRE: this.nombreSimulacion,
        VIENTOS: this.vientosLocales ? 1 : 0,
        GRAFICOS: graficos
      }

      let data = {
        datosSimulador: datosSimulador,
        datosBackend: datosBackend
      }

      try {
        // Enviamos al back
        await this.$store.dispatch('simulacion/addSimulacion', data)
        this.snackbarSuccess('Simulación solicitada correctamente')
        this.resetCampos()
      } catch (err) {
        this.snackbarError('Error solicitando simulacion')
      } finally {
        this.isLoadingRequest = false
      }
    },

    snackbarError (msg) {
      this.showSnackbar = true
      this.snackbarColor = 'error'
      this.snackbarText = msg
    },
    snackbarSuccess (msg) {
      this.showSnackbar = true
      this.snackbarColor = 'success'
      this.snackbarText = msg
    },
    // #endregion

    // #region LISTADO SIMULACIONES
    async refreshSimulaciones () {
      this.isLoadingSimulaciones = true

      try {
        let data = {
          fechaDesde: this.$date
            .parseDate(this.fechaDesde, 'DD/MM/YYYY')
            .format('YYYY-MM-DD HH:mm:ss.SSS'),
          fechaHasta: this.$date
            .parseDate(this.fechaHasta, 'DD/MM/YYYY')
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss.SSS')
        }
        await this.$store.dispatch('simulacion/fetchSimulaciones', data)
      } finally {
        this.isLoadingSimulaciones = false
      }
    },

    paintSimulacion (simulacion) {
      if (this.sim) {
        ArcGIS.clearSimulacion(this.sim)
      }
      this.sim = simulacion

      let data = {
        sim: simulacion,
        id: 'simulacion'
      }

      this.$emit('paintSimulacion', data)

      if (simulacion && simulacion.VIENTOS) {
        this.$emit('paintViento', simulacion)
      }
    },

    deleteSimulacion (simulacion) {
      this.$emit('deleteSimulacion', { simulacion: simulacion, callback: this.refreshSimulaciones })
    },

    descargarSimulacionSHP (simulacion) {
      ArcGIS.exportSimulacion(simulacion)
    },

    descargarSimulacionPDF (simulacion) {
      this.simulacionSelected = simulacion
      // this.$store.dispatch('isLoading', true)
      this.$emit('paintSimulacionEstadoFinal', { simulacion: simulacion, callback: this.exportarSimulacionPDFCallBack })
    },

    exportarSimulacionPDFCallBack (informe) {
      ArcGIS.captureMap().then(async (captura) => {
        await PDFHelper.pdfSimulacion(captura, this.simulacionSelected, informe)
      })
    },
    // #endregion

    cerrar () {
      this.$el.scrollTop = 0
      this.resetCampos()
      this.$emit('close')
    },

    setBorderWidth () {
      let i = this.$refs.drawer.$el.querySelector(
        '.v-navigation-drawer__border'
      )
      i.style.width = this.navigation.borderSize + 'px'
      i.style.cursor = 'ew-resize'
      i.style.backgroundColor = 'transparent'
    },

    setEvents () {
      const minSize = this.navigation.borderSize
      const el = this.$refs.drawer.$el
      const drawerBorder = el.querySelector('.v-navigation-drawer__border')
      const vm = this
      const direction = el.classList.contains('v-navigation-drawer--right')
        ? 'right'
        : 'left'

      function resize (e) {
        if (e.clientX < vm.navigation.minWidth) return
        document.body.style.cursor = 'ew-resize'
        let f =
      direction === 'right'
        ? document.body.scrollWidth - e.clientX
        : e.clientX
        el.style.width = f + 'px'
      }

      drawerBorder.addEventListener(
        'mousedown',
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = 'initial'
            document.addEventListener('mousemove', resize, false)
          }
        },
        false
      )

      document.addEventListener(
        'mouseup',
        () => {
          el.style.transition = ''
          this.navigation.width = el.style.width
          document.body.style.cursor = ''
          document.removeEventListener('mousemove', resize, false)
        },
        false
      )
    }

  },
  mounted () {
    this.setBorderWidth()
    this.setEvents()
  }

}
</script>

<style scoped>
</style>
