<template>
  <div>
    <v-fade-transition>
      <div id="contextMenu" v-show="showContextMenu">
        <v-card>
          <v-toolbar color="primaryLight" dark dense>
            <v-toolbar-title>Tiempo de descanso</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-form v-model="isValid" @submit.prevent="aceptar">
              <v-container>
                <v-row>
                  <v-col>
                    <vx-time-text-edit label="Tiempo de descanso" v-model="tiempoDescanso" outlined hide-details :min="descansoMinimo" />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
            <v-btn text color="green darken-1" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import * as ArcGIS from '../../helpers/ArcGIS'
import constants from '../../helpers/constants'

export default {
  props: {
    showDescansoPopUp: Boolean
  },
  data: () => ({
    showContextMenu: false,
    buttons: [
      { label: 'Nuevo incendio', icon: 'mdi-plus', action: 'addIncendio' },
      { label: 'Zoom', icon: 'mdi-magnify', action: 'zoom' }
    ],

    showSnackBarError: false,

    clickPoint: null,

    tiempoDescanso: '12:00',

    medioSelected: null,

    item: null,

    isValid: false,

    oldDuracion: null
  }),

  computed: {
    descansoMinimo () {
      let t = this.medioSelected

      if (!t) return '00:00'

      let tiempoVuelo = t.PERIODOS[0].horaLlegadaBase.diff(t.PERIODOS[0].horaSalidaBase, 'minutes')
      let descanso = Math.ceil(tiempoVuelo / 60) * 20 // 20 min x hora de vuelo

      if (t.TIPO === 'ACO-Avión') {
        if (tiempoVuelo > constants.tiempoMaxVuelo.aco_avion * 60) { // Si ACO ha volado + de su maximo, solo descansa 1 hora
          descanso = 60
        } else if (descanso < 10) { // Si a ACO le corresponden - de 10 min de descanso, descansa 10 min
          descanso = 10
        }
      } else if (t.TIPO === 'ACT') {
        if (tiempoVuelo > constants.tiempoMaxVuelo.act * 60) { // Si ACT ha volado + de su maximo, solo descansa 1 hora
          descanso = 60
        } else if (descanso < 10) { // Si a ACT le corresponden - de 10 min de descanso, descansa 10 min
          descanso = 10
        }
      } else if (t.TIPO === 'AA') {
        if (tiempoVuelo > constants.tiempoMaxVuelo.hidroavion * 60) { // Si Hidroavion ha volado + de su maximo, solo descansa 1 hora 20 min
          descanso = 80
        } else if (descanso < 30) { // Si a Hidroavion le corresponden - de 30 min de descanso, descansa 30 min
          descanso = 30
        }
      } else {
        if (tiempoVuelo > 2 * 60) { // Si ha volado + de su maximo (2h), solo descansa 40 min
          descanso = 40
        } else if (descanso < 10) { // Si le corresponden - de 10 min de descanso, descansa 10 min
          descanso = 10
        }
      }

      let n = new Date(0, 0)
      n.setSeconds(descanso * 60)

      return n.toTimeString().slice(0, 5)
    }
  },

  methods: {
    contextMenu (data) {
      if (!data) {
        this.showContextMenu = false
      } else {
        this.medioSelected = data.medio

        this.item = data.item

        let n = new Date(0, 0)
        n.setSeconds(this.$date.parseDate(this.item.end).diff(this.$date.parseDate(this.item.start), 'minutes') * 60)

        this.tiempoDescanso = n.toTimeString().slice(0, 5)
        this.oldDuracion = this.$date.parseDate(this.item.end).diff(this.$date.parseDate(this.item.start), 'minutes')
        this.clickPoint = data
        this.updatePosition()
        this.showContextMenu = true
      }
    },

    updatePosition () {
      let positionX = this.clickPoint.position.x
      let positionY = this.clickPoint.position.y

      let menu = document.getElementById('contextMenu')
      menu.style.top = positionY + 'px'
      menu.style.left = positionX + 'px'
    },

    // ocultar () {
    //   console.log(this.showContextMenu)
    //   if (this.showContextMenu) this.showContextMenu = false
    // },

    cancelar () {
      this.$emit('cancelar')
      this.showContextMenu = false
    },

    aceptar () {
      if (this.descansoMinimo > this.tiempoDescanso) return false

      let data = {
        medioSelected: this.medioSelected,
        tiempoDescanso: this.tiempoDescanso,
        item: this.item,
        oldDuracion: this.oldDuracion
      }
      this.$emit('aceptar', data)
      this.showContextMenu = false
    }
  },

  mounted () {
    this.$eventHub.$on('showPopUpDescanso', this.contextMenu)
  }
}
</script>

  <style scoped>
    #contextMenu {
      z-index: 1000; /* Numero arbitrario para que se coloque en frente de la barra roja del diagrama */
      position: absolute !important;
      max-width: 250px;
      background-color: white;
    }
    .contextOption {
      transition: 0.3s;
    }
    .contextOption:hover {
      background-color: #00749e;
      color: white;
    }
  </style>
