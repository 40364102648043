<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Editar base de repostaje</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-form v-model="isValid">
            <v-row>
              <v-col>
                <v-select v-model="baseSelected" :items="bases" prepend-icon="esri-icon-description" label="Base repostaje" item-text="NOMBRE" item-value="ID_BASE" single-line autocomplete />
              </v-col>
            </v-row>
          </v-form>
          <v-btn
            class="my-3"
            :loading="calculandoTiempos"
            @click="baseMasRapida"
          >
            <v-icon>mdi-speedometer</v-icon>
            Calcular base más rápida
          </v-btn>
          <v-row>
            <v-col>
              <v-text-field label="Tiempo de ida a repostaje" :value="tiempoRepostaje" readonly outlined hide-details />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green darken-1" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import * as PH from '../../helpers/PlanificacionHelper'

export default {
  props: {
    show: Boolean,
    medioSelected: Object,
    idIncendio: String
  },

  data: () => ({
    isValid: false,

    bases: [],
    baseSelected: null,

    tiempoRepostaje: null,

    calculandoTiempos: false
  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.bases = this.$store.getters['medio/bases']
        this.baseSelected = this.medioSelected.ID_BASE_REPOSTAJE_PLANIF ? this.medioSelected.ID_BASE_REPOSTAJE_PLANIF : this.bases.find((a) => a.NOMBRE === this.medioSelected.BASE).ID_BASE
      }
    },

    baseSelected () {
      let medioPreliminar = JSON.parse(JSON.stringify(this.medioSelected))
      medioPreliminar.ID_BASE_REPOSTAJE_PLANIF = this.baseSelected

      let time = PH.calculateDistanceTimeToIncendio([medioPreliminar], this.incendio.LATITUD, this.incendio.LONGITUD)[0]

      this.tiempoRepostaje = time.FERRY_REPOSTAJE ? time.FERRY_REPOSTAJE : time.FERRY_INCENDIO
    }
  },

  methods: {
    cancelar () {
      this.$emit('cancelar')
    },

    aceptar () {
      this.medioSelected.ID_BASE_REPOSTAJE_PLANIF = this.baseSelected

      this.$emit('aceptar', this.medioSelected)
    },

    baseMasRapida () {
      this.calculandoTiempos = true

      let mediosPreliminares = []

      for (let i = 0; i < this.bases.length; i++) {
        let medioPreliminar = JSON.parse(JSON.stringify(this.medioSelected))

        medioPreliminar.ID_BASE_REPOSTAJE_PLANIF = this.bases[i].ID_BASE

        mediosPreliminares.push(medioPreliminar)
      }

      let tiempos = PH.calculateDistanceTimeToIncendio(mediosPreliminares, this.incendio.LATITUD, this.incendio.LONGITUD)

      tiempos.sort((a, b) => {
        if (a.FERRY_REPOSTAJE > b.FERRY_REPOSTAJE) return 1 // Si el tiempo de a es mayor, se envia al final de la cola

        if (a.FERRY_REPOSTAJE < b.FERRY_REPOSTAJE) return -1 // Si el tiempo de b es mayor, se envia al final de la cola

        // Tiempos iguales
        if (a.ID_BASE_REPOSTAJE_PLANIF === this.bases.find((a) => a.NOMBRE === this.medioSelected.BASE).ID_BASE) return -1 // Si la base de la estimacion es la misma que la del medio, prevalece esta

        if (b.ID_BASE_REPOSTAJE_PLANIF === this.bases.find((a) => a.NOMBRE === this.medioSelected.BASE).ID_BASE) return 1 // Si la base de la estimacion es la misma que la del medio, prevalece esta

        return 0
      })

      this.tiempoRepostaje = tiempos[0].FERRY_REPOSTAJE
      this.baseSelected = tiempos[0].ID_BASE_REPOSTAJE_PLANIF

      this.calculandoTiempos = false
    }

  }
}
</script>

<style scoped>
</style>
