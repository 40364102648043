<template>
  <v-text-field ref="input" :value="date" @focus="onFocus" @input="onInput" :label="label" :prepend-icon="icon" :disabled="disabled" :rules="allRules" v-facade="facade" v-mask="mask" :outlined="outlined" :dense="dense" />
</template>

<script>
export default {
  name: 'DateTextEdit',

  model: {
    prop: 'date',
    event: 'input'
  },

  props: {
    date: [String, Object],

    label: String,
    icon: String,
    outlined: Boolean,
    dense: Boolean,

    disabled: {
      type: Boolean,
      default: false
    },

    min: [String, Object],
    max: [String, Object],
    between: [String, Object],

    onlyDate: {
      type: Boolean,
      default: false
    },

    notPast: { // Date cannot be past
      type: Boolean,
      default: false
    },
    notFuture: { // Date cannot be future
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
  }),

  computed: {
    allRules () {
      let rules = []

      let length = this.onlyDate ? 10 : 16

      rules.push(v => (!v || v.length === length || v.length === 0) || 'Fecha inválida.')
      rules.push(this.isDateValid)

      if (this.required) {
        rules.push(v => !!v || 'Campo obligatorio.')
      }

      if (this.min) {
        rules.push(this.isDateAfterThan)
      }

      if (this.max) {
        rules.push(this.isDateBeforeThan)
      }

      if (this.between) {
        rules.push(this.isDateBetween)
      }

      if (this.notPast) {
        rules.push(this.isDatePast)
      }

      if (this.notFuture) {
        rules.push(this.isDateFuture)
      }
      return rules
    },

    dateFormat () {
      let dateFormat = 'DD/MM/YYYY HH:mm'

      if (this.onlyDate) {
        dateFormat = 'DD/MM/YYYY'
      }

      return dateFormat
    },

    mask () {
      let mask = '##/##/#### ##:##'

      if (this.onlyDate) {
        mask = '##/##/####'
      }

      return mask
    },

    facade () {
      let mask = '##/##/#### ##:##'

      if (this.onlyDate) {
        mask = '##/##/####'
      }

      return mask
    }
  },

  watch: {
    min () {
      this.$refs.input.validate() // TODO: ¿ESTO PUEDE QUE CAMBIE EL METODO Y SE ROMPA ALGUN DIA?
    },
    max () {
      this.$refs.input.validate() // TODO: ¿ESTO PUEDE QUE CAMBIE EL METODO Y SE ROMPA ALGUN DIA?
    },
    between () {
      this.$refs.input.validate() // TODO: ¿ESTO PUEDE QUE CAMBIE EL METODO Y SE ROMPA ALGUN DIA?
    }
  },

  methods: {
    isDateValid (v) {
      return (!v || v === '' || this.$date.parseDate(v, this.dateFormat).isValid()) || 'Fecha inválida.'
    },

    isDateAfterThan (v) { // Used if 'min' prop enabled
      if (v && v.length > 0) {
        let dateMin = this.$date.parseDate(this.min, this.dateFormat)
        let date = this.$date.parseDate(v, this.dateFormat)
        return (dateMin <= date) || 'La fecha se sale de los límites.'
      } else {
        return true
      }
    },

    isDateBeforeThan (v) { // Used if 'max' prop enabled
      if (v && v.length > 0) {
        let dateMax = this.$date.parseDate(this.max, this.dateFormat)
        let date = this.$date.parseDate(v, this.dateFormat)
        return (date <= dateMax) || 'La fecha se sale de los límites.'
      } else {
        return true
      }
    },

    isDateBetween (v) { // Used if 'between' prop enabled
      let response = true
      if ((!v || v === '') && this.between) {
        response = false
      }

      return response || 'La fecha es requerida.'
    },

    isDatePast (v) {
      if (v && v.length > 0) {
        let now = this.$date.now()
        let date = this.$date.parseDate(v, this.dateFormat)
        return date >= now || 'La fecha no puede ser anterior a ahora'
      } else {
        return true
      }
    },

    isDateFuture (v) {
      if (v && v.length > 0) {
        let now = this.$date.now()
        let date = this.$date.parseDate(v, this.dateFormat)
        return date <= now || 'La fecha no puede ser a futuro.'
      } else {
        return true
      }
    },

    isValid () {
      let v = this.date
      let rules = this.allRules

      for (let i = 0; i < rules.length; i++) {
        let isValid = rules[i](v)

        if (isValid !== true) { // (Si no cumple la regla devuelve string, no boolean)
          return false
        }
      }

      return true
    },

    onInput (event) {
      this.$emit('input', event)
    },

    onFocus (event) {
      this.$emit('focus', event)
    }
  }
}
</script>
