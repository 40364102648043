<template>
  <v-text-field ref="input" :value="time" @input="onInput" :label="label" :prepend-icon="icon" :disabled="disabled" :rules="allRules" v-mask="mask" :outlined="outlined" :dense="dense" />
</template>

<script>
export default {
  name: 'TimeTextEdit',

  model: {
    prop: 'time',
    event: 'input'
  },

  props: {
    time: [String, Object],

    label: String,
    icon: String,
    outlined: Boolean,
    dense: Boolean,

    min: [String, Object],
    max: [String, Object],

    disabled: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    timeFormat: 'HH:mm',
    mask: '##:##'
  }),

  computed: {
    allRules () {
      let rules = []

      let length = 5

      rules.push(v => (!v || v.length === length || v.length === 0) || 'Hora inválida.')
      rules.push(this.isTimeValid)

      if (this.min) {
        rules.push(this.isTimeAfterThat)
      }

      if (this.max) {
        rules.push(this.isTimeBeforeThat)
      }

      if (this.required) {
        rules.push(v => !!v || 'Campo obligatorio.')
      }

      return rules
    }
  },

  watch: {
    min () {
      this.$refs.input.validate() // TODO: ¿ESTO PUEDE QUE CAMBIE EL METODO Y SE ROMPA ALGUN DIA?
    },
    max () {
      this.$refs.input.validate() // TODO: ¿ESTO PUEDE QUE CAMBIE EL METODO Y SE ROMPA ALGUN DIA?
    }
  },

  methods: {

    // TODO: Hacer que falle si el tiempo es anterior al recogido
    isTimeAfterThat (v) { // Used if 'min' prop enabled
      if (v && v.length > 0) {
        let minutosMinimos = this.min.split(':')[0] * 60 + parseInt(this.min.split(':')[1])
        let minutosUsuario = v.split(':')[0] * 60 + parseInt(v.split(':')[1])

        return (minutosMinimos <= minutosUsuario) || 'El tiempo se sale de los límites.'
      } else {
        return true
      }
    },

    isTimeBeforeThat (v) { // Used if 'max' prop enabled
      if (v && v.length > 0) {
        let minutosMaximos = this.max.split(':')[0] * 60 + parseInt(this.max.split(':')[1])
        let minutosUsuario = v.split(':')[0] * 60 + parseInt(v.split(':')[1])

        return (minutosUsuario <= minutosMaximos) || 'El tiempo se sale de los límites.'
      } else {
        return true
      }
    },

    isTimeValid (v) {
      return (!v || v === '' || this.$date.parseTime(v).isValid()) || 'Hora inválida.'
    },

    isValid () {
      let v = this.date
      let rules = this.allRules

      for (let i = 0; i < rules.length; i++) {
        let isValid = rules[i](v)

        if (isValid !== true) { // (Si no cumple la regla devuelve string, no boolean)
          return false
        }
      }

      return true
    },

    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
