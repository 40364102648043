<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-card-title class="px-0 pt-0">
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Eliminar incendio</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-container v-if="incendio" class="pa-0">
          <p class="texto-justificado texto-size">
            Estás a punto de borrar el incendio con nombre <b>{{ incendio.MUNICIPIO ? incendio.MUNICIPIO : incendio.LOCALIDAD }}</b>. Esta acción no se puede deshacer.
            <br><br>¿Estás seguro de que deseas continuar?
          </p>
        </v-container>

        <v-checkbox
          v-model="confirmado"
          label="Soy consciente de que voy a borrar el incendio"
          color="primary"
          class="mt-4 texto-size"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="red" :disabled="!confirmado" @click="aceptar">Eliminar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    show: Boolean,
    incendio: Object
  },

  data: () => ({
    confirmado: false
  }),
  methods: {
    obtenerDatos () {

    },

    aceptar () {
      this.$store.dispatch('medio/updateEstadoMedio', this.incendio)
      this.$store.dispatch('incendio/deleteIncendio', this.incendio)
      this.confirmado = false
      this.$emit('eliminar')
    },
    cancelar () {
      this.confirmado = false
      this.$emit('cancelar')
    }
  }
}
</script>

<style scoped>
.texto-justificado {
  text-align: justify;
  margin: 0; /* Elimina márgenes adicionales */
  padding: 0; /* Elimina rellenos adicionales */
}
.texto-size {
  font-size: 16px; /* Ajusta el tamaño de fuente para párrafo y label */
  line-height: 1.5; /* Alinea la altura de línea para consistencia */
}
</style>
