<template>
  <v-main id="content">
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col xs="12" sm="8" md="2" align="center">
          <v-fade-transition>
            <div>
              <img class="mb-4" src="../assets/junta_extremadura.svg">

              <v-card class="elevation-12 pa-3" v-show="!loading">
                <v-card-text>
                  <v-form v-model="isValid">
                    <v-text-field v-model="credentials.USUARIO" prepend-icon="mdi-account" label="Usuario" type="text" :rules="[globalRules.required]" />
                    <v-text-field v-model="credentials.PASSWD" prepend-icon="mdi-lock" label="Contraseña" type="password" :rules="[globalRules.required]" @keyup.enter="initLogin" />
                  </v-form>
                </v-card-text>

                <v-card-actions class="justify-center">
                  <v-btn color="primary" width="25%" :disabled="!isValid" @click="initLogin">Login</v-btn>
                </v-card-actions>
              </v-card>

              <v-progress-circular v-show="loading" color="white" indeterminate size="100" width="5" />
            </div>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>

    <span class="bottomLabel" style="left: 10px">v{{ version }}</span>
    <a href="https://www.vexiza.com"> <img class="bottomLabel" src="../assets/logo_vexiza_blanco.svg" style="width:100px; right: 10px;"> </a>

    <v-snackbar v-model="showSnackbarError" bottom color="error" :timeout="5000">
      <v-icon>mdi-lock</v-icon>
      {{ errorMessage }}
    </v-snackbar>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

const version = require('../../package.json').version

export default {
  name: 'Login',

  data: () => ({
    isValid: false,

    version: version,

    credentials: {},

    loading: false,

    showSnackbarError: false,
    errorMessage: ''
  }),

  computed: {
    ...mapGetters('auth', [
      'accessToken'
    ])
  },

  methods: {
    ...mapActions('app', [
      'init'
    ]),
    ...mapActions('auth', [
      'login'
    ]),

    async initLogin () {
      this.loading = true

      try {
        await this.login(this.credentials)

        await this.init() // Si el login es correcto, init descarga todos los datos a la store
        this.$router.push('/')
      } catch (err) {
        this.loading = false
        this.showError('Login incorrecto.')

        if (err && err.response) {
          switch (err.response.status) {
            case 401:
              this.showError('Credenciales incorrectas.')
              break
          }
        }
      }
    },
    showError (msg) {
      this.errorMessage = msg
      this.showSnackbarError = true
    }
  },

  mounted () {
    let reason = this.$route.query.reason

    if (reason && reason === 'token_invalid') {
      this.showError('Your token has expired or is invalid. Please login again.')
    }
  }
}
</script>

<style scoped>
  #content {
    box-shadow: inset 0 0 0 1000px #00496fcf;
    background-color: var(--v-primary-base);
    background-image: url('~@/assets/login_background.jpg');
    background-position: center;
    background-size: cover;
  }

  .bottomLabel {
    color: white;
    position: absolute;
    bottom: 0px;
  }
</style>
