import request from '../request'

export default {
  getMedios: function () {
    return request.get('medio')
  },

  getCategoriasMedio: function () {
    return request.get('medio_categoria')
  },

  getTiposMedio: function () {
    return request.get('medio_tipo')
  },

  getBases: function () {
    return request.get('base')
  },

  getZonasMedio: function () {
    return request.get('medio_zona')
  },

  addMedio: function (data) {
    return request.post('medio', data)
  },
  updateMedio: function (data) {
    return request.put(`medio/${data.ID_MEDIO}`, data)
  },
  deleteMedio: function (data) {
    return request.delete(`medio/${data.ID_MEDIO}`)
  },
  getIdDispositivo: function (idMedio) {
    return request.get(`medio/${idMedio}`)
  },

  getEmergencias: function () {
    return request.get('emergencia_medio')
  },
  setEmergenciaMedio: function (data) {
    return request.post('emergencia_medio', data)
  },

  getTrackMedio: function (idMedio, timeBack) {
    return request.get(`medio_posicion/${idMedio}/${timeBack}`)
  },

  getTrackFechasMedio: function (idDispositivo, dateInit, dateEnd) {
    return request.get(`medio_posicion/${idDispositivo}/${dateInit}/${dateEnd}`)
  },
  getTrackFechasInPolygon: function (data) {
    return request.post('medios_posicion_polygon/', data)
  }
}
