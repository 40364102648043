<template>
  <v-dialog v-model="show" persistent max-width="450px">
    <v-card>
      <v-card-title class="px-0 pt-0">
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Resumen incendio</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-container class="mt-2">
          <div>
            <v-row v-for="(item, key) in attrs" :key="key" dense>
              <v-divider class="pb-3" v-if="item.name==='separator'" />
              <v-col v-if="item.name!=='separator'">
                {{ item.name }}:
              </v-col>
              <v-col v-if="item.name!=='separator'">
                {{ item.value ? item.value : 'Sin Datos' }}
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="red" text @click="cerrar()">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    show: Boolean,
    attrs: Array
  },

  data: () => ({
  }),

  methods: {
    cerrar () {
      this.$emit('hide')
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>
