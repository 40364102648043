<template>
  <div class="fill-height white">
    <v-toolbar color="primaryLight" dark dense style="z-index: 2;">
      <v-toolbar-title>Actuaciones incendios</v-toolbar-title>

      <v-spacer />

      <!-- <v-switch v-model="showIncendios" label="Incendios" inset hide-details/> -->
      <!-- TODO: Si dicen algo, hay que meter aqui la otra vista -->
      <v-btn icon @click="initTimeLinesIncendios">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container fluid style="height: calc(100vh - 112px); overflow-y: auto">
      <div id="mediosContainer" class="timelineContainer" v-show="!showIncendios" />

      <div class="timelineContainer" v-show="showIncendios">
        <v-expansion-panels v-model="panelsOpened" multiple>
          <v-expansion-panel class="mt-2" v-for="(item, i) in incendiosActivos" :key="i">
            <v-expansion-panel-header class="text-subtitle-1" :style="{'background-color': item.COLOR_ESTADO, 'border-color': item.COLOR_ESTADO}">
              {{ item.NOMBRE ? item.NOMBRE : item.LOCALIDAD }} - {{ item.MUNICIPIO }}
            </v-expansion-panel-header>

            <v-expansion-panel-content eager>
              <div class="mt-3" :id="item.ID_INCENDIO" style="display: block" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- <div class="mt-2" v-for="(item, i) in incendiosActivos" :key="i">
          <span class="pa-1 pl-3" style="font-size: 20px; display: flex; cursor: pointer;" v-bind:style="{
            'background-color':  item.COLOR_ESTADO,
            'border-color': item.COLOR_ESTADO
          }" v-on:click="toggleVisibilidad(item.ID_INCENDIO)">{{item.LOCALIDAD}} - {{item.MUNICIPIO}}</span>
          <div :id="item.ID_INCENDIO" style="display: block"/>
        </div> -->
      </div>
    </v-container>
  </div>
</template>

<script>
import constants from '../../helpers/constants'
import api from '../../api'
import { Timeline, DataSet } from 'vis-timeline/standalone'
import Q from 'q'

export default {
  components: {
  },

  data: () => ({
    showIncendios: true,

    timeline: null,

    // actuacionesIncendios: [],

    // INCENDIOS
    panelsOpened: [],
    incendiosActivos: [],
    timelinesIncendiosActivos: [],

    updateActuaciones: [], // TODO: para que se usa?
    updateActuacionesStart: []
  }),

  computed: {
    medios () {
      return this.$store.getters['medio/medios']
    }
  },

  methods: {
    initTimeline () {
      let container = document.getElementById('mediosContainer')
      this.timeline = new Timeline(container)
      this.timeline.setOptions(this.optionsTimeLine())
    },

    optionsTimeLine () {
      let minTimeLine = this.$date.now().subtract(1, 'year')
      let maxTimeLine = this.$date.now().add(6, 'month')
      let initTimeLine = this.$date.now().subtract(6, 'hours')
      let endTimeLine = this.$date.now().add(6, 'hours')

      let options = {
        stack: false,
        showTooltips: true,
        tooltip: {
          followMouse: true,
          overflowMethod: 'cap'
        },
        orientation: 'top',
        zoomMin: 1000000,
        maxHeight: '100%',
        verticalScroll: true,
        min: minTimeLine,
        max: maxTimeLine,
        start: initTimeLine,
        end: endTimeLine
      }
      return options
    },

    // #region INCENDIOS
    async loadActuacionesIncendiosActivos () {
      let deferred = Q.defer()

      let daysTimeBack = constants.daysTimeBackIncendios
      let COD_CCAA = constants.comunidad.CODIGO_INE_CCAA
      let COD_PROV = constants.comunidad.CODIGO_INE_PROVINCIA

      let data = {
        daysTimeBack,
        COD_CCAA,
        COD_PROV
      }

      try {
        let response = await api.actuaciones.getActuacionesIncendiosActivos(data)
        if (response.status === 200) {
          this.incendiosActivos = this.orderIncendios(response.data)
          deferred.resolve()
        } else {
          // TODO¿¿¿
        }
      } catch (err) {
        deferred.reject(err)
      }

      return deferred.promise
    },

    orderIncendios (incendios) {
      let orderIncendios = incendios
      orderIncendios.sort((a, b) => {
        let aEstado = a.VALUE_ESTADO
        let bEstado = b.VALUE_ESTADO
        let aFecha = a.FECHA_CREACION
        let bFecha = b.FECHA_CREACION

        if (aEstado === bEstado) {
          return (aFecha > bFecha) ? -1 : (aFecha < bFecha) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      return orderIncendios
    },

    toggleVisibilidad (idIncendio) {
      let stateElement = document.getElementById(idIncendio).style.display

      if (stateElement === 'block') {
        document.getElementById(idIncendio).style.display = 'none'
      } else {
        document.getElementById(idIncendio).style.display = 'block'
      }
    },

    async initTimeLinesIncendios () {
      this.panelsOpened = []

      for (let i = 0; i < this.timelinesIncendiosActivos.length; i++) {
        this.timelinesIncendiosActivos[i].destroy()
      }
      this.timelinesIncendiosActivos = []

      try {
        await this.loadActuacionesIncendiosActivos()

        for (let i = 0; i < this.incendiosActivos.length; i++) {
          this.panelsOpened.push(i)

          let container = document.getElementById(this.incendiosActivos[i].ID_INCENDIO)
          let timeLine = new Timeline(container)
          let initDate = this.$date.parseDate(this.incendiosActivos[i].FECHA_CREACION).subtract(3, 'days').valueOf()
          let endDate = this.$date.now().add(1, 'day').valueOf()

          let options = {
            selectable: false,
            editable: false,
            stack: false,
            showTooltips: true,
            tooltip: {
              followMouse: true,
              overflowMethod: 'cap'
            },
            orientation: 'top',
            zoomKey: 'ctrlKey',
            zoomMin: 1000000,
            maxHeight: '100%',
            verticalScroll: true,
            min: initDate,
            max: endDate
          }
          timeLine.setOptions(options)

          let groups = this.getGroupsIncendiosActivos(this.incendiosActivos[i].ID_INCENDIO)
          timeLine.setGroups(new DataSet(groups))

          let items = this.getItemsIncendiosActivos(this.incendiosActivos[i].ID_INCENDIO)
          items = this.convertDatesToUNIX(items)

          // Elimino los que no tienen fecha inicio
          let dataSetItemsIncendios = new DataSet(items.filter(x => x.start))

          timeLine.setItems(dataSetItemsIncendios)

          timeLine.on('currentTimeTick', () => {
            let now = this.$date.now().valueOf()

            for (let j = 0; j < this.updateActuaciones.length; j++) {
              let elemtActualizar = dataSetItemsIncendios.get(this.updateActuaciones[j])
              if (elemtActualizar) {
                dataSetItemsIncendios.update({ id: elemtActualizar.id, end: now })
              }
            }

            for (let j = 0; j < this.updateActuacionesStart.length; j++) {
              let elemtActualizar = dataSetItemsIncendios.get(this.updateActuacionesStart[j])
              if (elemtActualizar) {
                dataSetItemsIncendios.update({ id: elemtActualizar.id, start: now })
              }
            }
          })

          this.timelinesIncendiosActivos.push(timeLine)
        }
      } catch (err) {
        this.$log.error(err)
      }
    },

    getGroupsIncendiosActivos (idIncendio) {
      let groups = []

      let incendio = this.incendiosActivos.find(x => x.ID_INCENDIO === idIncendio)

      if (incendio) {
        for (let j = 0; j < incendio.MEDIOS.length; j++) {
          let medio = incendio.MEDIOS[j]
          groups.push({
            id: medio.ID_MEDIO,
            content: '<span align="left">' + medio.MEDIO + '</span>',
            value: medio.MEDIO,
            className: 'medio' // TODO: y esta clase?
          })
        }
      }

      return groups
    },
    getItemsIncendiosActivos (idIncendio) {
      let item
      let items = []

      let incendio = this.incendiosActivos.find(x => x.ID_INCENDIO === idIncendio)

      if (incendio) {
        for (let j = 0; j < incendio.MEDIOS.length; j++) {
          let medio = incendio.MEDIOS[j]

          for (let l = 0; l < medio.HORAS.length; l++) {
            let horaIncendio = medio.HORAS[l]
            let dateAviso = this.$date.parseDate(horaIncendio.FECHA_AVISO).format('DD/MM/YYYY HH:mm')

            if (horaIncendio.FECHA_LLEGADA) {
              let dateLlegada = this.$date.parseDate(horaIncendio.FECHA_LLEGADA).format('DD/MM/YYYY HH:mm')

              item = {
                id: this.$uuid.createUUID(),
                value: medio.MEDIO,
                group: medio.ID_MEDIO,
                content: 'Aviso',
                start: horaIncendio.FECHA_AVISO,
                end: horaIncendio.FECHA_LLEGADA,
                className: 'medio_aviso',
                title: 'Aviso: ' + dateAviso + '</br>Llegada: ' + dateLlegada + ''
              }
              items.push(item)

              let hourSalidaTurno = this.$date.parseDate(medio.HORA_SALIDA).utc().format('HH:mm') // TODO: ??

              if (horaIncendio.FECHA_SALIDA) {
                let dateSalida = this.$date.parseDate(horaIncendio.FECHA_SALIDA)
                let dateFinTurno = this.$date.parseDate(this.$date.parseDate(horaIncendio.FECHA_SALIDA).format('DD/MM/YYYY') + hourSalidaTurno, 'DD/MM/YYYY HH:mm')

                if (dateFinTurno > dateSalida) {
                  item = {
                    id: this.$uuid.createUUID(),
                    value: medio.MEDIO,
                    group: medio.ID_MEDIO,
                    content: 'Actuando',
                    start: horaIncendio.FECHA_LLEGADA,
                    end: horaIncendio.FECHA_SALIDA,
                    className: 'medio_actuando',
                    title: 'Llegada: ' + dateLlegada + '</br>Fin: ' + dateSalida.locale('es').format('DD/MM/YYYY HH:mm') + ''
                  }
                  items.push(item)
                } else {
                  item = {
                    id: this.$uuid.createUUID(),
                    value: medio.MEDIO,
                    group: medio.ID_MEDIO,
                    content: 'Actuando',
                    start: horaIncendio.FECHA_LLEGADA,
                    end: dateFinTurno,
                    className: 'medio_actuando',
                    title: 'Llegada: ' + dateLlegada + '</br>Fin turno: ' + dateFinTurno.locale('es').format('DD/MM/YYYY HH:mm') + ''
                  }
                  items.push(item)

                  item = {
                    id: this.$uuid.createUUID(),
                    value: medio.MEDIO,
                    group: medio.ID_MEDIO,
                    content: 'Fuera de turno',
                    start: dateFinTurno,
                    end: horaIncendio.FECHA_SALIDA,
                    className: 'medio_actuaciones',
                    title: 'Fin turno: ' + dateFinTurno.locale('es').format('DD/MM/YYYY HH:mm') + '</br>Fin incendio: ' + dateSalida.locale('es').format('DD/MM/YYYY HH:mm')
                  }
                  items.push(item)
                }

                item = {
                  id: this.$uuid.createUUID(),
                  value: medio.MEDIO,
                  group: medio.ID_MEDIO,
                  start: horaIncendio.FECHA_SALIDA,
                  end: horaIncendio.FECHA_SALIDA,
                  type: 'point',
                  title: 'Fin:' + dateSalida.locale('es').format('DD/MM/YYYY HH:mm'),
                  content: '<img src="/img/fin-incendio.svg" alt="Fin" style="margin-top: -7px; margin-left: -15px; width:37px">' // TODO:
                }
                items.push(item)
              } else {
                let now = this.$date.now()
                let dateFinTurno = this.$date.parseDate(now.format('DD/MM/YYYY') + hourSalidaTurno, 'DD/MM/YYYY HH:mm')

                if (dateFinTurno > now) {
                  item = {
                    id: this.$uuid.createUUID(),
                    value: medio.MEDIO,
                    group: medio.ID_MEDIO,
                    content: 'Actuando',
                    start: horaIncendio.FECHA_LLEGADA,
                    end: now,
                    className: 'medio_actuando',
                    title: 'Llegada: ' + dateLlegada + ''
                  }
                  items.push(item)
                  this.updateActuaciones.push(item.id)
                } else {
                  item = {
                    id: this.$uuid.createUUID(),
                    value: medio.MEDIO,
                    group: medio.ID_MEDIO,
                    content: 'Actuando',
                    start: horaIncendio.FECHA_LLEGADA,
                    end: dateFinTurno,
                    className: 'medio_actuando',
                    title: 'Llegada: ' + dateLlegada + '</br>Fin Turno: ' + dateFinTurno.format('DD/MM/YYYY HH:mm') + ''
                  }
                  items.push(item)

                  item = {
                    id: this.$uuid.createUUID(),
                    value: medio.MEDIO,
                    group: medio.ID_MEDIO,
                    content: 'Fuera de turno',
                    start: dateFinTurno,
                    end: now,
                    className: 'medio_actuaciones',
                    title: 'Actuando fuera de turno'
                  }
                  items.push(item)
                  this.updateActuaciones.push(item.id)
                }
              }
            } else {
              item = {
                id: this.$uuid.createUUID(),
                value: medio.MEDIO,
                group: medio.ID_MEDIO,
                content: 'Aviso',
                start: horaIncendio.FECHA_AVISO,
                end: this.$date.now(),
                className: 'medio_aviso',
                title: 'Aviso: ' + dateAviso + ''
              }
              items.push(item)
              this.updateActuaciones.push(item.id)
            }
          }
          items = this.getItemsTrabajoIncendios(incendio, medio).concat(items)
        }
      }

      return items
    },
    getItemsTrabajoIncendios (incendio, medio) {
      let now = this.$date.now()

      let fechaCreacionIncendio = this.$date.parseDate(incendio.FECHA_CREACION)
      let daysIncendio = now.startOf('day').diff(fechaCreacionIncendio.startOf('day'), 'days') + 4 // +4 porque empieza a pintar las jornadas 2 dias antes

      let fechaActuacion = fechaCreacionIncendio.subtract(3, 'days')

      let actuacionesMedioInDay = []
      let item
      let items = []

      for (let i = 0; i < daysIncendio; i++) {
        let medioEntrada = this.$date.parseDate(fechaActuacion.format('YYYY-MM-DD') + 'T' + medio.HORA_ENTRADA.substring(11, 16), 'YYYY-MM-DDThh:mm')
        let medioSalida = this.$date.parseDate(fechaActuacion.format('YYYY-MM-DD') + 'T' + medio.HORA_SALIDA.substring(11, 16), 'YYYY-MM-DDThh:mm')

        if (medio.HORA_ENTRADA > medio.HORA_SALIDA) {
          medioSalida = medioSalida.add(1, 'days')
        }

        actuacionesMedioInDay = this.getActuacionesMedioInDay(fechaActuacion, medio.HORAS)
        if (actuacionesMedioInDay.length < 1) {
          item = {
            id: this.$uuid.createUUID(),
            value: medio.MEDIO,
            group: medio.ID_MEDIO,
            content: 'Jornada',
            start: medioEntrada,
            end: medioSalida,
            className: 'medio_trabajo',
            title: medioEntrada.format('HH:mm') + ' - ' + medioSalida.format('HH:mm')
          }
          items.push(item)
        } else {
          for (let j = 0; j < actuacionesMedioInDay.length; j++) {
            let actuacionMedio = actuacionesMedioInDay[j]
            let fechaAviso = this.$date.parseDate(actuacionMedio.FECHA_AVISO)

            let fechaSalida
            if (actuacionMedio.FECHA_SALIDA != null) {
              fechaSalida = this.$date.parseDate(actuacionMedio.FECHA_SALIDA)
            } else {
              fechaSalida = this.$date.now()
            }

            // Primer caso
            if (medioEntrada < fechaAviso && actuacionMedio.FECHA_SALIDA < medioEntrada) {
              item = {
                id: this.$uuid.createUUID(),
                value: medio.MEDIO,
                group: medio.ID_MEDIO,
                content: 'Jornada',
                start: medioEntrada,
                end: fechaAviso,
                className: 'medio_trabajo',
                title: medioEntrada.format('HH:mm') + ' - ' + fechaAviso.format('HH:mm')
              }
              items.push(item)
            }

            let ultimaSalida = fechaSalida

            if (ultimaSalida < medioSalida) {
              item = {
                id: this.$uuid.createUUID(),
                value: medio.MEDIO,
                group: medio.ID_MEDIO,
                content: 'Jornada',
                start: ultimaSalida,
                end: fechaAviso,
                className: 'medio_trabajo',
                title: ultimaSalida.format('HH:mm') + ' - ' + fechaAviso.format('HH:mm')
              }
              items.push(item)
            }

            if ((j === (actuacionesMedioInDay.length - 1)) && ultimaSalida < medioSalida) {
              if (actuacionMedio.FECHA_SALIDA === null) {
                item = {
                  id: this.$uuid.createUUID(),
                  value: medio.MEDIO,
                  group: medio.ID_MEDIO,
                  content: 'Jornada',
                  start: ultimaSalida.add(1, 'minutes'),
                  end: medioSalida,
                  className: 'medio_trabajo',
                  title: 'Jornada'
                }
                items.push(item)
                this.updateActuacionesStart.push(item.id)
              } else {
                item = {
                  id: this.$uuid.createUUID(),
                  value: medio.MEDIO,
                  group: medio.ID_MEDIO,
                  content: 'Jornada',
                  start: ultimaSalida,
                  end: medioSalida,
                  className: 'medio_trabajo',
                  title: 'Jornada'
                }
                items.push(item)
              }
            }
          }
        }

        fechaActuacion = fechaActuacion.add(1, 'days')
      }

      return items
    },
    getActuacionesMedioInDay (fecha, actuaciones) {
      let actuacionesInFecha = []
      let fechaComprobar = fecha.format('DD/MM/YYYY')

      for (let i = 0; i < actuaciones.length; i++) {
        let act = actuaciones[i]
        let fechaActuacion = this.$date.parseDate(act.FECHA_AVISO).format('DD/MM/YYYY')

        if (fechaComprobar === fechaActuacion) {
          actuacionesInFecha.push(act)
        }
      }
      return actuacionesInFecha
    },
    // #endregion

    // TODO: IMPORTANTE -> VIS.JS no soporta nativamente DayJS asi que es necesario pasar las fechas en otro formato (string, unix, etc) por eso se usa .valueOf()
    // ¿Quiza algun dia esto no haga falta?
    convertDatesToUNIX (items) {
      let newItems = JSON.parse(JSON.stringify(items))

      /* for (let i = 0; i < newItems.length; i++) {
        newItems[i].start = newItems[i].start.valueOf()
        newItems[i].end = newItems[i].end.valueOf()
      } */

      return newItems
    }
  },

  mounted () { // TODO: no es reactivo
    // this.initTimeLine()
    // this.updateTimeLine()
    this.initTimeLinesIncendios()
  }
}
</script>

<style scoped>
  @import '../../../node_modules/vis-timeline/dist/vis-timeline-graph2d.min.css';

  ::v-deep .medio_aviso {
    background-color: #f1b047!important;
    border-color: #f1b047!important;
  }

  ::v-deep .medio_actuando {
    background-color: #f14d5a !important;
    border-color: #f14d5a !important;
  }

  ::v-deep .medio_actuaciones {
    color: black;
  }

  ::v-deep .medio_salida {
    background-color: #43ef5b;
  }

  ::v-deep .medio_base {
    background-color: #ccc8c5;
  }
  ::v-deep .medio_trabajo {
    background-color: green !important;
    border-color: green !important;
  }
</style>
