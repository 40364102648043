import { render, staticRenderFns } from "./LeftMenu.vue?vue&type=template&id=221fbdf0&scoped=true&"
import script from "./LeftMenu.vue?vue&type=script&lang=js&"
export * from "./LeftMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221fbdf0",
  null
  
)

export default component.exports