import request from '../request'

export default {
  getMediosAereos: function () {
    return request.get('planificacion_medios_aereos')
  },

  setVueltaAgoncillo: function (data) {
    return request.put(`set_vuelta_agoncillo/${data.ID_MEDIO_SECTOR}/${data.AGONCILLO}`)
  },

  setBaseRepostaje: function (data) {
    return request.put(`set_base_vuelta_repostaje/${data.ID_MEDIO_SECTOR}/${data.ID_BASE_REPOSTAJE_PLANIF}`)
  },

  addPeriodoDescanso: function (data) {
    return request.post('periodo_descanso/', data)
  }
}
