<template>
  <div>
    <v-card elevation="5">
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Datos incendio</v-toolbar-title>

        <v-progress-circular class="ml-4" v-show="updatingEstadoIncendio || updatingNivelIncendio" indeterminate />

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right absolute @click="showDialogEditIncendio = true" :disabled="disabledByRol">
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar incendio</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-bind="attrs" v-on="on" color="secondary" v-show="showAddNiveles" :disabled="disableAddNivel || disabledByRol" bottom right absolute @click="changeVisibilityNivelIncendio" :style="{marginRight: '50px'}">
              <!--:style="{marginRight: '50px'}"-->
              <v-icon color="primary">mdi-car-brake-fluid-level</v-icon>
            </v-btn>
          </template>
          <span v-if="disableAddNivel">Es necesario finalizar el anterior nivel</span>
          <span v-else>Añadir nivel incendio</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text>
        <div>
          <p class="subtitle-1 black--text">Municipio: {{ incendio.MUNICIPIO }}</p>
          <p class="subtitle-1 black--text">Localidad: {{ incendio.LOCALIDAD }}</p>
          <p class="subtitle-1 black--text">Coordenadas: {{ incendio.LATITUD }}, {{ incendio.LONGITUD }}</p>
          <p class="subtitle-1 black--text">Detección: {{ deteccion.DETECCION }}</p>
          <p class="subtitle-1 black--text">Observaciones: {{ incendio.OBSERVACIONES }}</p>
        </div>

        <v-data-table :headers="headers" :items="incendio.ESTADOS" hide-default-footer>
          <template #[`header.ACCIONES`]="{ }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="showIncendioEstadoEdit()">
                  <v-icon color="black">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar estado incendio</span>
            </v-tooltip>
          </template>
          <template #no-data>
            <p>Datos no disponibles.</p>
          </template>

          <template #[`item.FECHA`]="{ item }">
            <span>{{ item.FECHA | formatDate }}</span>
          </template>

          <template #[`item.ESTADO`]="{ item }">
            <v-chip class="chip" :color="item.TIPO_INCENDIO_ESTADO.COLOR" dark>
              {{ item.TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO }}
            </v-chip>
          </template>

          <template #[`item.ACCIONES`]="{ item }">
            <!--<v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="showIncendioEstadoEdit(item.TIPO_INCENDIO_ESTADO.VALUE)">
                  <v-icon color="black">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar estado incendio</span>
            </v-tooltip>-->

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <div class="d-inline-block" v-bind="attrs" v-on="on">
                  <v-btn icon :disabled="disableFinalizar" v-if="showAddEstado(item)" @click="setEstadoIncendio(item.TIPO_INCENDIO_ESTADO)">
                    <v-icon color="black">mdi-plus</v-icon>
                  </v-btn>
                </div>
              </template>
              <span v-if="disableFinalizar">Es necesario finalizar las actuaciones de los medios <br>y finalizar el ultimo nivel del incendio</span>
              <span v-else>Añadir estado incendio</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" v-show="showRemoveEstado(item)" @click="deleteEstadoIncendio()">
                  <v-icon color="black">mdi-minus</v-icon>
                </v-btn>
              </template>
              <span>Quitar estado incendio</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-data-table :headers="headers2" :items="incendio.NIVELES" hide-default-footer v-show="showNivelesTable">
          <template #[`item.NIVEL`]="{ item }">
            <v-chip class="chip" :color="item.TIPO_INCENDIO_NIVEL.COLOR" dark>
              {{ item.TIPO_INCENDIO_NIVEL.INCENDIO_NIVEL }}
            </v-chip>
          </template>
          <template #[`item.FECHA_INICIO`]="{ item }">
            <span>{{ $date.formatDate(item.FECHA_INICIO,'DD/MM/YYYY HH:mm') }}</span>
          </template>
          <template #[`item.FECHA_FIN`]="{ item }">
            <span>{{ $date.formatDate(item.FECHA_FIN,'DD/MM/YYYY HH:mm') }}</span>
          </template>

          <template #[`item.ACCIONES`]="{ item, index }">
            <!--<template #[`item.ACCIONES`]="{ item,index}">-->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="showEditNivelDialog(item,index)" :disabled="disabledByRol">
                  <v-icon color="black">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar nivel incendio</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <div class="d-inline-block" v-bind="attrs" v-on="on">
                  <!-- Hack para mostrar tooltip con boton disabled -->
                  <v-btn icon v-show="showRemoveNivel(item)" @click="deleteNivelIncendio()" :disabled="disabledByRol">
                    <v-icon color="black">mdi-minus</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Eliminar el nivel</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <vx-dialog-edit-incendio :show="showDialogEditIncendio" :incendio="incendio" @aceptar="aceptarEditIncendio" @cancelar="showDialogEditIncendio = false" />
    <vx-dialog-add-nivel-incendio :show="showAddNivelIncendioDialog" @aceptar="changeVisibilityNivelIncendio" @cancelar="changeVisibilityNivelIncendio" :idIncendio="idIncendio" @redrawIncendio="redrawIncendio" />
    <vx-dialog-edit-nivel-incendio :show="showEditNivelIncendioDialog" @close="showEditNivelIncendioDialog = false" :idIncendio="incendio.ID_INCENDIO" :dataNivel="dataNivel" @redrawIncendio="redrawIncendio" />
    <vx-dialog-edit-incendio-estado :show="showDialogEstadoEdit" :incendio="incendio" @close="showDialogEstadoEdit = false" />
  </div>
</template>

<script>
/* eslint-disable */

import DialogAddNivelIncendio from './DialogAddNivelIncendio.vue'
import DialogEditNivelIncendio from './DialogEditNivelIncendio.vue'
import DialogEditIncendio from './DialogEditIncendio'
import DialogEditIncendioEstado from './DialogEditIncendioEstado'

import * as ArcGIS from '../../../helpers/ArcGIS'
import * as PermisosHelper from '../../../helpers/PermisosHelper'

export default {
  components: {
    'vx-dialog-edit-incendio-estado': DialogEditIncendioEstado,
    'vx-dialog-edit-incendio': DialogEditIncendio,
    'vx-dialog-add-nivel-incendio': DialogAddNivelIncendio,
    'vx-dialog-edit-nivel-incendio': DialogEditNivelIncendio

  },

  props: {
    idIncendio: String
  },

  data: () => ({
    headers: [
      { text: 'FECHA', value: 'FECHA', align: 'left', sortable: true },
      { text: 'ESTADO', value: 'ESTADO', align: 'center', sortable: false },
      { text: 'ACCIONES', value: 'ACCIONES', align: 'center', width: '25%', sortable: false }
    ],
    headers2: [
      { text: 'FECHA INICIO', value: 'FECHA_INICIO', align: 'left', sortable: true },
      { text: 'FECHA FIN', value: 'FECHA_FIN', align: 'left', sortable: true },
      { text: 'NIVEL', value: 'NIVEL', align: 'center', sortable: false },
      { text: '', value: 'ACCIONES', align: 'center', width: '25%', sortable: false }
    ],

    updatingEstadoIncendio: false,
    updatingNivelIncendio: false,
    
    showAddNivelIncendioDialog: false,
    showEditNivelIncendioDialog: false,
    dataNivel:null,

    showDialogEditIncendio: false,
    
    showDialogEstadoEdit: false,
    showDialogEstadoAdd: false,

    codigoTipoEstadoEdit: null,
    dateFormat: 'DD/MM/YYYY HH:mm',

  }),

  computed: {
    incendio () {
      let incendio = JSON.parse(JSON.stringify(this.$store.getters['incendio/getIncendioByID'](this.idIncendio)))
      return incendio || {}
    },

    deteccion () {
      let deteccion = this.incendio ? this.$store.getters['incendio/getTipoDeteccionById'](this.incendio.ID_TIPO_DETECCION) : null
      return deteccion || {}
    },
    disableFinalizar () {
      return (!this.actuacionesCerradas && this.incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE === 3) || (this.showNivelesTable && this.incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE === 3)
    },
    showNivelesTable() {
      return this.incendio.NIVELES && this.incendio.NIVELES.length > 0
    },
    showAddNiveles() {
      if(this.incendio.ESTADOS){
        return this.incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE !== 4
      } 
      else return false
    },

    disableAddNivel(){
      return !this.incendio.NIVELES? false: this.incendio.NIVELES.length>0?  this.incendio.NIVELES[0].FECHA_FIN ==null: false
    },

    actuacionesCerradas () {
      for (let i = 0; i < this.incendio.SECTORES.length; i++) {
        let sector = this.incendio.SECTORES[i]

        for (let j = 0; j < sector.MEDIOS.length; j++) {
          let medio = sector.MEDIOS[j]

          if (!medio.FECHA_LLEGADA_BASE) {
            return false
          }
        }
      }
      return true
    },     
    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !PermisosHelper.disableForExterno()
    }
  },

  watch: {
    incendio: {
      handler (val, oldVal) {
        if ((val && val.ID_INCENDIO) && (oldVal && oldVal.ID_INCENDIO)) {
          const didLatLonChange = val.LATITUD !== oldVal.LATITUD || val.LONGITUD !== oldVal.LONGITUD
          const didEstadosChange = val.ESTADOS.length !== oldVal.ESTADOS.length
          if (didLatLonChange || didEstadosChange) {
            this.redrawIncendio()
          }
        }
      },
      deep: true
    }
  },

  methods: {
    showEditNivelDialog(item,index){
      this.dataNivel = item 
      this.dataNivel = {...this.dataNivel, index :index}
      this.showEditNivelIncendioDialog = true
    },
    showRemoveNivel(item){
      return item.ID_INCENDIO_NIVEL === this.incendio.NIVELES[0].ID_INCENDIO_NIVEL

    },
    changeVisibilityNivelIncendio () {
      this.updatingEstadoIncendio = !this.updatingEstadoIncendio
      this.showAddNivelIncendioDialog = !this.showAddNivelIncendioDialog
    },
    showAddEstado (item) {
      let isEstadoMasReciente = item.TIPO_INCENDIO_ESTADO.VALUE === this.incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE
      let noIsFinalizado = this.incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE !== 4

      // Comprobacion dos estados en el mismo minuto
      let date = new Date(this.incendio.ESTADOS[0].FECHA)
      let dateNow = new Date() 

      let isSameMinute = (dateNow.getTime() - date.getTime()) > 60000 // 60000 ms = 1 min
      return isEstadoMasReciente && noIsFinalizado && isSameMinute
    },

    showRemoveEstado (item) {
      let isEstadoMasReciente = item.TIPO_INCENDIO_ESTADO.VALUE === this.incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE
      let noIsInicio = this.incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE !== 0

      return isEstadoMasReciente && noIsInicio
    },

    aceptarEditIncendio (incendio) {
      this.$store.dispatch('incendio/editIncendio', incendio)
      this.showDialogEditIncendio = false
    },

    setEstadoIncendio (estado) {
      if (this.updatingEstadoIncendio) return

      this.updatingEstadoIncendio = true
      let estadosIncendio = this.$store.getters['incendio/tiposIncendioEstado']

      let estadoAdd
      for (let i = 0; i < estadosIncendio.length; i++) {
        if (estado.VALUE === estadosIncendio[i].VALUE) {
          estadoAdd = estadosIncendio[i + 1]
          break
        }
      }

      let estadoIncendio = {
        ID_INCENDIO_ESTADO: this.$uuid.createUUID(),
        ID_INCENDIO: this.idIncendio,
        ID_TIPO_INCENDIO_ESTADO: estadoAdd.ID_TIPO_INCENDIO_ESTADO,
        FECHA: this.$date.now(),
        TIPO_INCENDIO_ESTADO: estadoAdd
      }

      this.$store.dispatch('incendio/addEstadoIncendio', estadoIncendio).then(() => {
      }).catch((e) => {
        this.$notify({
                  title: `Cambio de estado`,
                  text: `Error al cambiar el estado del incendio a ${estadoAdd.INCENDIO_ESTADO}`,
                  type: 'error'
                })
      }).finally(() => {
        this.updatingEstadoIncendio = false
      })

    },

    deleteEstadoIncendio () {
      if (this.updatingEstadoIncendio) return

      this.updatingEstadoIncendio = true

      let data = {
        ID_INCENDIO_ESTADO: this.incendio.ESTADOS[0].ID_INCENDIO_ESTADO,
        ID_INCENDIO: this.incendio.ESTADOS[0].ID_INCENDIO,
        NUEVO_ESTADO: this.incendio.ESTADOS[1].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO
      }

      this.$store.dispatch('incendio/deleteEstadoIncendio', data)
      setTimeout(() => {
        this.updatingEstadoIncendio = false
      }, 1000)
    },
    deleteNivelIncendio () {
      if (this.updatingNivelIncendio) return

      this.updatingNivelIncendio = true

      let data = {
        ID_INCENDIO_NIVEL: this.incendio.NIVELES[0].ID_INCENDIO_NIVEL,
        ID_INCENDIO: this.incendio.NIVELES[0].ID_INCENDIO,
      }

      this.$store.dispatch('incendio/deleteNivelIncendio', data)
      setTimeout(() => {
        this.redrawIncendio()
        this.updatingNivelIncendio = false
      }, 1000)
    },

    showIncendioEstadoEdit () {
      this.showDialogEstadoEdit = true
    },

    redrawIncendio () {
      ArcGIS.redrawIncendios()
    },

  }
}
</script>

<style scoped>
  .chip {
    width: 150px;
  }

  .chip >>> .v-chip__content { /* Centrar texto chips */
    margin: auto !important;
  }
</style>
