import * as ArcGIS from './ArcGIS'
import constants from './constants'

import store from '@/store'

let isCancelRefresh = false
/**
 * Devuelve una lista con los medios, su tipo, y el tiempo que tardan en llegar a las coordenadas
 */
export const waitUntilReady = function (resolve) {
  const interval = setInterval(() => {
    let fetching = store.getters['app/fetching']
    if (!fetching) resolve(interval)
  }, 500)
}

export const cancelRefresh = function (cancel) {
  let fetching = store.getters['app/fetching']

  if (fetching && cancel) {
    isCancelRefresh = true
    new Promise(waitUntilReady).then((timer) => {
      clearInterval(timer)
      isCancelRefresh = false
    })
  }
}

export const getIsCancelRefresh = function () {
  return isCancelRefresh
}
