<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model="medio" label="Medio" :rules="[globalRules.required]" outlined dense />
              </v-col>

              <v-col>
                <v-autocomplete v-model="tipoSelected" label="Tipo" :items="tipos" item-text="TIPO" item-value="TIPO" return-object :rules="[globalRules.required]" outlined dense clearable />
              </v-col>
            </v-row>

            <v-row v-show="tipoSelected && tipoSelected.CATEGORIA === 'Aéreo'">
              <v-col>
                <v-text-field label="Velocidad máxima (km/h)" v-model="velocidadMaxima" type="number" maxlength="9" outlined dense />
              </v-col>
              <v-col>
                <v-text-field label="Modelo" v-model="modelo" outlined dense />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field label="Nombre" v-model="nombre" outlined dense />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field label="Teléfono interno" type="number" maxlength="9" v-model="tlfInterno" outlined dense />
              </v-col>

              <v-col>
                <v-text-field label="Teléfono externo" type="number" maxlength="9" v-model="tlfExterno" outlined dense />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete label="Organismo" :items="organismos" item-text="ORGANISMO" item-value="ORGANISMO" v-model="organismoSelected" return-object outlined dense clearable />
              </v-col>

              <v-col>
                <v-text-field label="Guardias" v-model="guardias" outlined dense />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete label="Base" :items="bases" item-text="NOMBRE" item-value="NOMBRE" v-model="baseSelected" return-object outlined dense clearable />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-menu ref="menuHoraEntrada" v-model="showHoraEntrada" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="horaEntrada" label="Hora entrada" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense />
                  </template>
                  <v-time-picker v-model="horaEntrada" v-show="showHoraEntrada" format="24hr">
                    <v-spacer />
                    <v-btn text color="primary" @click="showHoraEntrada = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.menuHoraEntrada.save(horaEntrada)">OK</v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>

              <v-col>
                <v-menu ref="menuHoraSalida" v-model="showHoraSalida" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="horaSalida" label="Hora salida" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense />
                  </template>
                  <v-time-picker v-model="horaSalida" v-show="showHoraSalida" format="24hr">
                    <v-spacer />
                    <v-btn text color="primary" @click="showHoraSalida = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.menuHoraSalida.save(horaSalida)">OK</v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'
import constants from '../../helpers/constants'

export default {
  props: {
    show: Boolean,
    isEdit: Boolean,
    editedMedio: Object
  },

  data: () => ({
    isValid: false,

    medio: null,
    velocidadMaxima: null,
    modelo: null,
    nombre: null,
    tlfInterno: null,
    tlfExterno: null,
    guardias: null,

    showHoraEntrada: false,
    horaEntrada: null,
    showHoraSalida: false,
    horaSalida: null,

    tipoSelected: null,
    organismoSelected: null,
    baseSelected: null
  }),

  computed: {
    ...mapGetters('medio', [
      'tiposMedio'
    ]),

    accionDialog () {
      return this.isEdit ? 'Editar' : 'Nuevo'
    },

    tipos () {
      let tipos = JSON.parse(JSON.stringify(this.tiposMedio))
      return tipos.sort((a, b) => constants.collator.compare(a.TIPO, b.TIPO))
    },

    bases () {
      let bases = JSON.parse(JSON.stringify(this.$store.getters['medio/bases']))
      return bases.sort((a, b) => constants.collator.compare(a.NOMBRE, b.NOMBRE))
    },

    organismos () {
      let organismos = JSON.parse(JSON.stringify(this.$store.getters['organismo/organismos']))
      return organismos.sort((a, b) => constants.collator.compare(a.ORGANISMO, b.ORGANISMO))
    }
  },

  watch: {
    show () {
      if (this.show) {
        if (this.isEdit) {
          this.medio = this.editedMedio.MEDIO

          this.tipoSelected = this.tiposMedio.find(x => x.TIPO === this.editedMedio.TIPO)

          this.velocidadMaxima = this.editedMedio.VELOCIDAD_MAX
          this.modelo = this.editedMedio.MODELO

          this.nombre = this.editedMedio.NOMBRE

          this.tlfInterno = this.editedMedio.TELEFONO_INTERNO
          this.tlfExterno = this.editedMedio.TELEFONO_EXTERNO

          this.organismoSelected = this.organismos.find(x => x.ORGANISMO === this.editedMedio.ORGANISMO)

          this.guardias = this.editedMedio.GUARDIAS

          this.baseSelected = this.bases.find(x => x.NOMBRE === this.editedMedio.BASE)

          this.horaEntrada = this.editedMedio.HORA_ENTRADA
          this.horaSalida = this.editedMedio.HORA_SALIDA
        }
      }
    }
  },

  methods: {
    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },

    aceptar () {
      let medio = this.isEdit ? JSON.parse(JSON.stringify(this.editedMedio)) : {}

      if (this.tipoSelected.CATEGORIA === 'Aéreo') {
        medio.VELOCIDAD_MAX = this.velocidadMaxima
        medio.MODELO = this.modelo
      } else {
        medio.VELOCIDAD_MAX = null
        medio.MODELO = null
      }

      medio.MEDIO = this.medio

      // Tipo / Categoria
      medio.TIPO = this.tipoSelected.TIPO
      medio.CATEGORIA = this.tipoSelected.CATEGORIA
      medio.ID_MEDIO_TIPO = this.tipoSelected.ID_MEDIO_TIPO

      medio.NOMBRE = this.nombre

      medio.TELEFONO_INTERNO = this.tlfInterno
      medio.TELEFONO_EXTERNO = this.tlfExterno

      // Organismo
      medio.ID_ORGANISMO = this.organismoSelected && this.organismoSelected !== '' ? this.organismoSelected.ID_ORGANISMO : 'NULL'

      medio.GUARDIAS = this.guardias

      // Base
      medio.BASE = this.baseSelected.NOMBRE === '' ? '' : this.baseSelected.NOMBRE
      medio.ID_BASE = this.baseSelected.ID_BASE === '' ? 'NULL' : this.baseSelected.ID_BASE
      medio.HORA_ENTRADA = this.horaEntrada
      medio.HORA_SALIDA = this.horaSalida

      if (this.isEdit) {
        this.$emit('editMedio', medio)
      } else {
        medio.ID_MEDIO = this.$uuid.createUUID()
        medio.ESTADO = 0
        this.$emit('addMedio', medio)
      }

      this.$refs.form.reset()
    }
  }
}
</script>

<style scoped>
</style>
