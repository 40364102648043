<template>
  <!--la referencia es única para cada clase-->
  <div ref="perimeterSymbology">
    <v-card
      v-show="showPerimeterSymbology"
      color="#537582CC"
      dark
      elevation="6"
      width="500px"
      style="position: absolute; right: 70px; bottom: 60px"
    >
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title class="text-symbology">Ventana para crear el perímetro</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="toggleMinimize">
          <v-icon>
            {{
              minimized ? "mdi-window-maximize" : "mdi-window-minimize"
            }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-color-picker
        v-model="colorSelected"
        v-show="showSelectedColor"
        light
        dot-size="25"
        hide-inputs
      />
      <v-container>
        <!-- Color y tipo linea -->
        <v-row justify="center">
          <v-col sm="5">
            <v-btn tile :color="colorSelectedHex" @click="showSelectedColor = !showSelectedColor">
              <v-icon v-show="showSelectedColor">mdi-close</v-icon>
              Color
            </v-btn>
          </v-col>
          <v-col>
            <v-select
              class="pa-0"
              v-model="lineSelected"
              :items="getTipoLinea"
              label="Tipo perímetro"
              hide-details
            >
              <template #selection="{ item }">
                <img
                  :src="
                    require(`@/assets/new_simbologia/${item.src}`)
                  "
                  height="40"
                  width="100"
                >
              </template>
              <template #item="{ item }">
                <img
                  :src="
                    require(`@/assets/new_simbologia/${item.src}`)
                  "
                  height="40"
                  width="100"
                >
              </template>
            </v-select>
          </v-col>
          <!-- Botones ESRI -->
          <v-col v-for="item in esriButtons" :key="item.id">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn :class="{activeBTN: activeBtn === `${item.id}Button`}" icon v-on="on" @click="clickEsriSymbol(item.id)">
                  <v-icon small>{{ item.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.label }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- Snackbar finalizar TODOS los dibujos -->
    <v-snackbar v-model="showSnackBar" button :timeout="-1" color="#28a745" elevation="24" width="700px" height="80px" style="margin-bottom: 15px;">
      <span style="font-size: 24px; padding: 10px;">¿Añadir perímetro?</span>
      <template #action="{ attrs }">
        <v-btn text color="white" v-bind="attrs" @click="finishDrawing" style="font-size: larger;">
          Aceptar
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Snackbar dibujo actual -->
    <v-snackbar v-model="isDrawing" button :timeout="-1">
      Haz click en el mapa.
      <template #action="{ attrs }">
        <v-btn text color="pink" v-bind="attrs" @click="cancelCurrentDrawing">
          Cancelar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as ArcGIS from '../../helpers/ArcGIS'
export default {
  data: () => ({
    showPerimeterSymbology: false,
    callback: null,
    minimized: false,
    showSelectedColor: false,
    colorSelected: {},
    activeBtn: null,
    showSnackBar: false,
    isDrawing: false,
    graphics: [],
    lineSelected: null,
    esriButtons: [ // listado de botones, en este caso solo necesito el de poligonos
      { id: 'polygon', label: 'Perímetro', icon: 'esri-icon-polygon' }
    ],
    tipoLinea: [ // con esto cargo las 4 líneas mas sencillas que hay
      { style: 'solid', icon: 'line_solid.png' },
      { style: 'short-dot', icon: 'line_short-dot.png' },
      { style: 'short-dash', icon: 'line_short-dash.png' },
      { style: 'short-dash-dot-dot', icon: 'line_short-dash-dot-dot.png' }
    ]
  }),
  computed: {
    ...mapGetters('map', [
      'newLines' // aquí obtengo las 4 líneas
    ]),
    colorSelectedHex () {
      return this.colorSelected.hex
    },
    getTipoLinea () {
      return this.newLines.filter((elem) => elem.isPerimetro) // filtro las líneas que son perimetro
    }
  },
  methods: {
    showPanelPerimeterSymbology (data) {
      this.showPerimeterSymbology = true
      this.showSnackBar = true
      this.callback = data.callback // Funcion a la que se va a llamar al finalizar, se recibe por el eventhub y se le envian los graphics
      this.graphics = data.graphics
      ArcGIS.initPerimetroSketchViewModel({
        callbackCreate: this.onDrawCreated,
        callbackUpdate: this.onDrawUpdated,
        callbackDelete: this.onDrawDeleted
      })
      ArcGIS.drawGraphicsPerimetro(this.graphics)
    },
    toggleMinimize () {
      this.minimized = !this.minimized
    },
    clickEsriSymbol (evt) {
      this.isDrawing = true
      this.activeBtn = evt + 'Button'
      this.paintEsriSymbol(evt)
    },
    paintEsriSymbol (evt) {
      let color = [
        this.colorSelected.rgba.r,
        this.colorSelected.rgba.g,
        this.colorSelected.rgba.b,
        this.colorSelected.rgba.a
      ]
      let event = {
        function: evt,
        color: color,
        type_line: this.lineSelected ? this.lineSelected.isPerimetro : null, // le paso el estilo correspondiente al perimetro
        custom: true,
        label: this.lineSelected
      }
      this.paint(event)
    },
    // #endregion
    paint (event) {
      this.isDrawing = true
      ArcGIS.paintEventPerimetro(event)
    },
    // #region CALLBACKS ARCGIS
    onDrawCreated (data) {
      this.isDrawing = false
      this.activeBtn = null
      this.graphics.push(data)
    },
    onDrawUpdated (data) {
      let atributos = data.attributes
      let geometria = data.geometry
      let index = this.graphics.findIndex(
        (x) => x.attributes.ID_GRAFICO === atributos.ID_GRAFICO
      ) // TODO: attributes por ATRIBUTOS
      if (index !== -1) {
        this.graphics[index].attributes = atributos // TODO: nombres parametros
        this.graphics[index].geometry = geometria

        if (this.graphics[index].type === 'point') {
          this.graphics[index].geometry.x = geometria.longitude
          this.graphics[index].geometry.y = geometria.latitude
        }
      }
    },
    onDrawDeleted (idGrafico) {
      let index = this.graphics.findIndex(
        (x) => x.attributes.ID_GRAFICO === idGrafico // Aquí se pone ID_GRAFICO porque es el literal de comienzo de los atributos
      ) // TODO: attributes por ATRIBUTOS
      if (index !== -1) {
        this.graphics.splice(index, 1)
      }
    },
    // Cancela el dibujo actual
    cancelCurrentDrawing () {
      this.isDrawing = false
      this.activeBtn = null
      ArcGIS.paintEventPerimetro('cancel')
    },
    finishDrawing () {
      this.showPerimeterSymbology = false
      this.showSnackBar = false
      this.isDrawing = false
      this.activeBtn = null
      ArcGIS.stopPerimeterSketchViewModel()
      this.callback(this.graphics)
      this.callback = null
    }
  },
  watch: {
    showPerimeterSymbology () {
      this.showSelectedColor = false
    }
  },
  mounted () {
    this.lineSelected = this.getTipoLinea[0]
    this.colorSelected.rgba = {
      a: 0.7,
      b: 16,
      g: 16,
      r: 233
    }
    this.$eventHub.$on('showPerimeterSymbology', this.showPanelPerimeterSymbology)
  }
}
</script>
    <style scoped>
    .activeBTN {
      border: 2px solid red;
    }
    .activeBTNLabel {
      color: red;
    }
    </style>
