<!-- Alineaciones Campbell-->
<template>
  <div class="mt-2">
    <v-card elevation="5">
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Alineaciones Campbell</v-toolbar-title>
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right class="me-3" :disabled="editAlineaciones" @click="showDialogAlineacionesCampBell ? hideAlineacionesCampbell() : showAlineacionesCampbell()">
              <v-icon color="primary">{{ showDialogAlineacionesCampBell ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ showDialogAlineacionesCampBell ? 'Ocultar' : 'Visualizar' }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right class="me-3" @click="initAlineacionesCampbell">
              <v-icon color="primary">mdi-shape-polygon-plus</v-icon>
              <!-- <v-icon color="primary">mdi-vector-polygon</v-icon> -->
            </v-btn>
          </template>
          <span>Alineaciones de Campbell</span>
        </v-tooltip>
      </v-toolbar>
    </v-card>
  </div>
</template>
<script>

import * as ArcGIS from '../../../helpers/ArcGIS'

export default {

  components: {},

  props: {
    idIncendio: String
  },

  data: () => ({

    graphicList: [],
    editAlineaciones: false,
    showDialogAlineacionesCampBell: false
  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    }
  },

  methods: {
    initAlineacionesCampbell () {
      if (this.incendio.ALINEACIONES_CAMPBELL && this.incendio.ALINEACIONES_CAMPBELL.length > 0) {
        this.graphicList = this.incendio.ALINEACIONES_CAMPBELL[0].GRAFICOS_CAMPBELL
      } else {
        this.graphicList = []
      }
      this.editAlineaciones = true

      this.$eventHub.$emit('showAlineacionesCampbell', {
        callback: this.onDrawGraphicsEnded,
        graphics: JSON.parse(JSON.stringify(this.graphicList)) // Clonar lista
      })
    },
    showAlineacionesCampbell () {
      this.showDialogAlineacionesCampBell = true
      if (this.incendio.ALINEACIONES_CAMPBELL && this.incendio.ALINEACIONES_CAMPBELL.length > 0) {
        this.graphicList = this.incendio.ALINEACIONES_CAMPBELL[0].GRAFICOS_CAMPBELL
      } else {
        if (!this.graphicList) {
          this.graphicList = []
        }
      }
      ArcGIS.drawGraphicsAlineacionesCampbell(JSON.parse(JSON.stringify(this.graphicList)))
    },
    hideAlineacionesCampbell () {
      this.showDialogAlineacionesCampBell = false
      ArcGIS.stopAlineacionesCampbellSketchViewModel()
    },
    onDrawGraphicsEnded (graphics) {
      this.showDialogAlineacionesCampBell = false
      this.editAlineaciones = false
      this.graphicList = graphics

      let date = this.$date.parseDate(this.fecha)

      // console.log('Incendio data: ', this.incendio)
      // console.log('Alineaciones_campbell: ', this.incendio.ALINEACIONES_CAMPBELL)
      let newPlan = {}
      if (this.incendio.ALINEACIONES_CAMPBELL && this.incendio.ALINEACIONES_CAMPBELL.length > 0) {
        newPlan = {
          ID_ALINEACIONES_CAMPBELL:
      this.incendio.ALINEACIONES_CAMPBELL[0].ID_ALINEACIONES_CAMPBELL,
          ID_INCENDIO: this.idIncendio,
          GRAFICOS: this.graphicList,
          FECHA: date
        }
        // this.incendio.ALINEACIONES_CAMPBELL.push(newPlan)
      } else {
        newPlan = {
          ID_ALINEACIONES_CAMPBELL: this.$uuid.createUUID(),
          ID_INCENDIO: this.idIncendio,
          GRAFICOS: this.graphicList,
          FECHA: date
        }
      }

      // Convertir datos a modelo BD
      this.modelGraphicsList(newPlan)

      this.$store.dispatch('incendio/editAlineacionesCampbell', newPlan)
    },
    modelGraphicsList (alineaciones) {
      // Convertir datos a modelo BD
      for (let i = 0; i < alineaciones.GRAFICOS.length; i++) {
        let g = alineaciones.GRAFICOS[i]
        g.ID_GRAFICOS_CAMPBELL = g.attributes.ID_GRAFICOS_CAMPBELL // TODO: attributes -> ATRIBUTOS
        g.ID_ALINEACIONES_CAMPBELL = alineaciones.ID_ALINEACIONES_CAMPBELL

        // FIX Parseo de colores
        if (g.symbol.color && g.symbol.color.toRgba) {
          // TODO: symbol -> SIMBOLO
          g.symbol.color = g.symbol.color.toRgba()
        }
        if (g.symbol.outline && g.symbol.outline.color.toRgba) {
          g.symbol.outline.color = g.symbol.outline.color.toRgba()
        }
      }

      return alineaciones
    }
  },

  created () {}
}
</script>
  <style scoped></style>
